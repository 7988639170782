.portfolio-master {
	background: var(--md-color-white);
	width: 100%;
	box-sizing: border-box;

	#glossary-container {
		position: initial;
		overflow-x: auto;
		border: none;

		.glossary-main-container .glossary-terms-shortname a {
			padding: 5px;
		}
	}

	.no-data-container {
		margin: 50px 0;
	}

	.loader-container {
		margin: 20px 0;
	}

	div[data-class='edit-delete-container'] {
		.modal-overlay {
			background-color: unset;

			.modal-container {
				padding: 6px 12px 12px 12px;
				width: 354px;

				.modal-header {
					margin: 0px;
					padding-bottom: 5px;

					.heading {
						font-size: 14px;
					}
				}

				textarea {
					resize: none;
					height: 55px;
				}
			}

			.modal-footer {
				gap: 10px;
				padding: 0px;

				button {
					margin: 0px;
					width: 50%;
				}
			}
		}
	}

	.portfolio-container {
		.header {
			padding-bottom: 20px;
			padding-left: 10px;
			display: flex;
			gap: 10px;
			justify-content: space-between;

			.left-header {
				display: flex;
				gap: 10px;
				align-items: center;
			}

			.create-portfolio-button {
				color: var(--md-color-dark-gray);
				border: 1px solid var(--md-color-blue);
				background: var(--md-color-white);
				height: initial;
			}

			.create-portfolio-button::first-letter {
				color: var(--md-color-blue);
			}

			.right-header {
				display: flex;
				gap: 20px;
				align-items: center;
			}

			.print-help-button {
				display: flex;
				justify-content: space-between;
				gap: 5px;
				border: none;
				margin: 0;
				padding: 0;
				background: none;
				align-items: center;
				cursor: pointer;
			}
		}
	}

	#portfolio_tracker_table {
		padding: 0 10px;

		.portfolio-table-header {
			align-items: end;

			span {
				height: auto;
			}
		}

		.common-row {
			display: flex;
			justify-content: space-between;
			padding: 10px 0px;
			border-top: 1px solid var(--md-color-divider-gray);
			gap: 10px;

			>*:not(:first-child) {
				text-align: right;
				width: 12%;
			}

			>*:first-child {
				text-align: left;
				width: 16%;
			}

			.labelLink {
				color: var(--md-color-blue);
			}

			.click-icon {
				padding: 0px;
				margin: 0px;
				border: none;
				background: none;
				cursor: pointer;
			}
		}

		.last-row {
			border-top: 2px solid var(--md-color-blur-white);
		}

		.contentRow {
			padding-top: 8px;
			padding-right: 0px;
			margin-right: 11px;
			border-top: 1px solid var(--md-color-divider-gray);
		}

		.tableHeader:not(:first-child) {
			text-align: right;
		}
	}

	.portfolio-item-header {
		display: flex;
		justify-content: space-between;
		align-items: center;

		ul[data-testid='dropDownId'] {
			background: unset;
			border: none;
			min-height: 52px;

			li.selected-value {
				justify-content: initial;
			}

			li.selected-value:first-child {
				background: unset;
				min-height: 52px;
				border: none;

				span {
					color: var(--md-color-black);
					font-size: 36px;
					line-height: 36px;
					height: auto;
					font-family: var(--md-font-family-palatino-regular);

					&.name {
						width: initial;
						padding-left: 0px;
					}
				}
			}
		}

		>*:first-child {
			width: 50%;
		}

		>*:last-child {
			width: 50%;
		}

		.portfolio-item-actions {
			display: flex;
			gap: 15px;
			margin-right: 10px;

			div:first-child {
				margin-left: auto;
			}

			button {
				display: flex;
				gap: 4px;
				cursor: pointer;
				border: none;
				padding: 0px;
				margin: 0px;
				background-color: unset;
				align-items: center;
			}
		}
	}
}

@media screen and (min-width: 0px) and (max-width: 596px) {
	.watchlist-view {
		display: block;
	}

	.portfolio-master {
		.portfolio-container {
			.header {
				flex-direction: column;
				gap: 10px;
				width: 93%;

				.left-header {
					flex-wrap: wrap;
					justify-content: space-between;

					h1.heading-h1 {
						font-size: var(--md-font-size-large);
					}
				}

				.create-portfolio-button {
					width: 100%;
				}

				.help-icon {
					display: none;
				}
			}
		}

		#portfolio_tracker_table {
			.portfolio-table-header {
				display: none;
			}

			.single-porfolio {
				display: flex;
				flex-direction: column;
				padding: 16px 0px;
				border-top: 1px solid var(--md-color-dark-gray);

				.common-row {
					>*:not(:first-child) {
						text-align: unset;
						width: unset;
					}

					>*:first-child {
						text-align: unset;
						width: unset;
					}

					a.labelLink {
						font-size: var(--md-font-size-small);
						line-height: 20px;
					}
				}

				.top-row-portfolio {
					order: -1;
					border-top: none;
					padding-top: 0px;

					>*:first-child {
						width: 80%;
					}

					button {
						svg {
							width: 19px;
							height: 18px;
						}
					}
				}
			}

			.combined-portfolios {
				>*:first-child {
					border-top: none;
					padding-top: 0px;
				}
			}
		}

		.portfolio-item-header {
			>*:first-child {
				width: 60%;
			}

			.portfolio-item-actions {
				display: flex;
				gap: 15px;
				position: relative;

				div:first-child {
					position: absolute;
					right: 3%;
					width: 150px;
					margin-top: -9%;
				}
			}

			ul[data-testid='dropDownId'] {
				min-height: initial;

				li.basicDropDownValueContainer {
					padding: 0;
				}

				li.selected-value {
					justify-content: initial;

					&:first-child span {
						font-size: 20px;
						letter-spacing: 0;
						line-height: 32px;

						&.name {
							width: initial;
							padding-left: 0px;
						}
					}
				}
			}
		}
	}
}