#print-wrapper {
	pointer-events: none;

	#portfolio-tracker-tabs-container {
		.pie-chart-portfolio {
			width: 350px;
		}
	}
}

@media print {
	body {
		-webkit-print-color-adjust: exact;
		print-color-adjust: exact;
	}
}
