#news-article-container {
	background: var(--md-color-white);
	margin-top: -11% !important;
	border: none;

	.headingStyle {
		line-height: 26px;
		margin-top: 5%;
		margin-right: 3%;
	}
	.provider {
		margin-top: 1%;
		padding-bottom: 8px;

		.dividerProvider {
			font-size: 12px;
			line-height: 12px;
			display: inline-block;
			color: var(--md-color-gray);
			position: relative;
			font-family: var(--md-font-family-helvetica-neue-regular);
			letter-spacing: 0;
			margin: 0px 14px;
		}
		.print-button-container {
			gap: 5px;
			border: none;
			margin-left: 6%;
			padding: 0;
			background: none;
			align-items: center;
			cursor: pointer;

			.print-button {
				display: flex;
				align-items: flex-end;
				position: absolute;
				transform: translateY(-75%);
			}

			.print-button-heading {
				margin-left: 7px;
			}
		}
	}
	.newsContainer {
		.align-loader {
			padding-top: 5%;
		}
		.news-content-main {
			max-height: 68vh;
			overflow-y: auto;
			padding-right: 4px;

			.noData-container {
				min-height: 12vh;
				display: flex;
			}
		}
	}
}
@media screen and (min-width: 0px) and (max-width: 595px) {
	#news-article-container {
		.newsContainer {
			.news-content-main {
				max-height: 65vh;
			}
		}
	}
}
