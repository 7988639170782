.chart-main-container {
    .chart-container {
        min-height: 285px;
        width: 100%;
        padding-left: 10px;

        .volume-dropdown {
            padding: 5px;
            width: 50%;
            position: absolute;
            margin-top: 250px;

            .dropDownMain {
                padding: 5px;
                width: 20%;
            }
        }
    }

    .volume-chart-container {
        width: 100%;
        padding-left: 10px;

    }

    .delayed-market-data {
        font-family: var(--md-font-family-helvetica-neue-regular);
        font-size: 11px;
        line-height: 12px;
        padding-bottom: 15px;
        margin-top: 20px;

        span {
            color: var(--md-color-light-grey);
        }
    }

    .save-settings {
        display: flex;
        justify-content: space-between;
        width: 98%;
        margin: 10px 0px;
        padding-left: 10px;

        .modal-overlay {
            background-color: transparent;
        }

        .save-settings-fields {
            display: inline-flex;
            align-items: center;
            width: 75%;

            span {
                font-family: var(--md-font-family-helvetica-neue-medium) !important;
                font-size: 12px;
                line-height: 18px;
            }

            .dropDownMain {
                width: 30%;
                padding: 0px 20px;
            }

            .save-settings-check {
                display: flex;
                align-items: center;

                input {
                    height: 20px;
                }

                span {
                    padding-left: 5px;
                    font-family: var(--md-font-family-helvetica-neue-regular) !important;
                    color: var(--md-color-dark-gray);
                    font-size: 12px !important;
                    line-height: 14px;
                }
            }
        }

        .save-settings-button {
            text-align: right;
            width: 25%;

            button {
                &.secondary {
                    color: var(--md-color-dark-gray);
                }
            }
        }
    }
}

@media screen and (min-width: 0px) and (max-width: 595px) {
    .chart-main-container {
        .delayed-market-data {
            font-family: var(--md-font-family-helvetica-neue-regular);
            font-size: 11px;
            line-height: 12px;
            padding-bottom: 10px;
            margin-top: 20px;

            span {
                color: var(--md-color-light-grey);
                padding-bottom: 5px;
                display: block;
                width: 100%;
            }
        }

        .save-settings {
            display: block;
            justify-content: space-between;
            padding-left: 0px;

            .modal-overlay {
                background-color: transparent;
            }

            .save-settings-fields {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                span {
                    font-family: var(--md-font-family-helvetica-neue-medium) !important;
                    font-size: 12px !important;
                    line-height: 18px;
                    color: var(--md-color-black);
                }

                .dropDownMain {
                    width: 50%;
                    padding: 0px;
                }
            }

            .save-settings-button {
                width: 100%;
                display: flex;
                padding-top: 10px;
                justify-content: space-between;

                .save-settings-check {
                    padding-top: 8px;

                    input {
                        height: 14px;
                    }

                    span {
                        padding-left: 5px;
                        font-family: var(--md-font-family-helvetica-neue-regular);
                        color: var(--md-color-dark-gray);
                        font-size: 12px;
                        line-height: 14px;
                    }
                }

            }
        }
    }
}