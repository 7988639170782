#headline-news-container {
	background: var(--md-color-white);

	.header {
		margin-bottom: 12px;
	}

	.newsContainer {
		.newsDropDown {
			width: 100%;
		}

		.news-content-main {
			margin-top: 13px;

			.no-data {
				height: 330px;
			}

			.loader-container {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 330px;
			}

			.news-content {
				.provider {
					margin-top: 9px;
					padding-bottom: 8px;

					.dividerProvider {
						font-size: 12px;
						line-height: 12px;
						display: inline-block;
						color: var(--md-color-gray);
						position: relative;
						font-family: var(--md-font-family-helvetica-neue-regular);
						letter-spacing: 0;
						margin: 0px 14px;
					}
				}

				border-bottom: 1px solid var(--md-color-divider-gray);
			}

			.news-content:not(:first-child) {
				padding-top: 11px;
			}
		}

		.scrollable {
			max-height: 330px;
			overflow-y: auto;
		}
	}
}

@media screen and (min-width: 596px) and (max-width: 1023px) {
	#headline-news-container {
		.header {
			margin-bottom: 7px;
		}

		.newsContainer {
			.newsDropDown {
				width: 50%;
			}

			.news-content-main {
				margin-top: 12px;
				max-height: 190px;
			}
		}
		.loader-container,
		.no-data {
			height: 190px !important;
		}
	}
}

@media screen and (min-width: 0px) and (max-width: 595px) {
	#headline-news-container {
		.header {
			margin-bottom: 6px;
		}

		.newsContainer {
			.newsDropDown {
				box-sizing: border-box;
			}

			.news-content-main {
				margin-top: 11px;
				max-height: 250px;
			}
		}
		.loader-container,
		.no-data {
			height: 250px !important;
		}
	}
}
