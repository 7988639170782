.down {
	transform: rotate(0);
}

.up {
	transform: rotate(180deg);
}

.left {
	transform: rotate(90deg);
}

.right {
	transform: rotate(270deg);
}
