.statistics-container {
    .statistics-title {
        color: var(--md-color-black);
        font-family: var(--md-font-family-palatino-regular);
        font-size: var(--md-font-size-large);
        letter-spacing: 0;
        line-height: 32px;
    }

    .loader-wrapper {
        display: flex;
        align-items: center;
        min-height: 200px;
        justify-content: center;
    }

    .table-container table {

        th,
        td {
            text-align: right;

            &:first-child {
                text-align: left;
            }
        }
    }

    .desktop-view {
        table {
            tr {
                th {
                    font-family: var(--md-font-family-helvetica-neue-regular);
                }
            }
        }
    }

    .mobile-view {
        table {
            tr {
                td {
                    font-size: var(--md-font-size-small);
                    letter-spacing: 0;
                    line-height: 18px;
                    color: var(--md-color-black);

                    &:first-child {
                        font-family: var(--md-font-family-helvetica-neue-medium);
                        font-weight: 500;
                        padding-left: 0;
                    }

                    &:nth-child(2) {
                        font-family: var(--md-font-family-helvetica-neue-regular);
                        text-align: right;
                        padding-right: 0;
                    }
                }
            }
        }
    }
}