.news-container {
	>div:first-child {
		margin-bottom: 20px !important;
	}

	.loader {
		height: 240px;
	}

	.indicators {
		display: flex;
		margin-bottom: 12px;
		justify-content: space-between;
		vertical-align: baseline;

		.indicator-text {
			font-family: var(--md-font-family-helvetica-neue-regular);
			font-size: var(--md-font-size-small);
			line-height: 14px;
			color: var(--md-color-dark-gray);
		}

		.indicator-pill {
			display: flex;
			white-space: nowrap;

			.icon-1 {
				width: 14px;
				height: 14px;
				background-color: var(--md-color-light-blue-variant);
				margin-right: 10.5px;
			}

			.icon-2 {
				width: 14px;
				height: 14px;
				background-color: var(--md-color-dull-blue);
				margin-right: 10.5px;
			}

			.icon-3 {
				width: 14px;
				height: 14px;
				background-color: var(--md-color-mid-blue);
				margin-right: 10.5px;
			}

			.icon-4 {
				width: 14px;
				height: 14px;
				background-color: var(--md-color-blue-solid);
				margin-right: 10.5px;
			}

			.icon-label {
				font-family: var(--md-font-family-helvetica-neue-regular);
				font-size: var(--md-font-size-small);
				line-height: 14px;
				color: var(--md-color-dark-gray);
				margin-right: 20.5px;
			}

			.last-label {
				margin-right: 0px;
			}
		}
	}

	.selected-news-container {
		border-bottom: 1px solid var(--md-color-dark-gray);
		padding-top: 6px;
		padding-bottom: 18px;
		margin-bottom: 20px;

		.news-label {
			display: flex;
			justify-content: space-between;
			align-items: baseline;

			>h2 {
				font-weight: 600;
			}

			>a {
				color: var(--md-color-blue) !important;
				text-decoration: underline;
				font-family: var(--md-font-family-helvetica-neue-bold);
				font-size: var(--md-font-size-small);
				line-height: 15px;
			}
		}

		.story-count {
			display: inline-block;
			margin-bottom: 13px;

			>h5 {
				font-size: var(--md-font-size-small);
				line-height: 15px;
				font-weight: 600;
			}
		}

		.news-stories {
			max-height: 350px;
			overflow-y: auto;
		}
	}

	.headline-news-component {
		margin-bottom: 10px;
		margin-top: -15px;
	}

	.hide-label>a {
		display: none;
	}

	.dropdown-container {
		.dropdown-row {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			margin-bottom: 16px;

			.component-title>h2 {
				white-space: nowrap;
			}

			.dropdowns {
				display: flex;
				justify-content: space-around;
				align-items: center;
				width: 75%;

				.type-dropdown {
					display: flex;
					align-items: baseline;
					width: 33%;
				}

				.category-dropdown {
					width: 35%;
				}

				.period-dropdown {
					width: 22%;
					margin-bottom: 0px;
				}
			}

			.hide-label>a {
				color: var(--md-color-blue) !important;
				text-decoration: underline;
				font-family: var(--md-font-family-helvetica-neue-bold);
				font-size: var(--md-font-size-small);
				line-height: 15px;
				white-space: nowrap;
				display: block;
			}
		}

		.second-row {
			.second-row-dropdowns {
				width: 87%;

				.correlate-dropdown {
					display: flex;
					align-items: baseline;
					width: 77%;
					margin-left: 5px;

					.type-heading {
						margin-left: 16.4%;
					}
				}

				.update-button {
					width: 18%;

					>button {
						white-space: nowrap;
						padding: 11px 23px;
						height: 36px;
						line-height: 14px;
					}
				}
			}

			.hide-label>a {
				display: none;
			}
		}
	}

	.indicators-mobile {
		display: none;
	}
}

.type-heading {
	margin-right: 12px;

	>h6 {
		font-size: var(--md-font-size-small);
		line-height: 18px;
		font-family: var(--md-font-family-helvetica-neue-medium);
		white-space: nowrap;
	}
}

@media screen and (min-width: 596px) and (max-width: 1024px) {
	.news-container {
		.indicators {
			.indicator-text {
				width: 35%;
			}
		}

		.container-row {
			display: flex;

			.show-label {
				justify-content: end;
			}
		}

		.dropdown-container {
			.dropdown-row {
				width: 107%;

				.dropdowns {
					.type-dropdown {
						width: 34%;
					}

					.category-dropdown {
						width: 38%;
					}

					.period-dropdown {
						width: 22%;
						margin-bottom: 0px;
					}
				}

				.hide-label>a {
					display: none;
				}
			}

			.second-row {
				width: 99%;

				.second-row-dropdowns {
					width: 82%;

					.correlate-dropdown {
						.type-heading {
							margin-left: 27%;
						}

						width: 75%;
					}

					.update-button {
						width: 19%;
					}
				}

				.hide-label>a {
					color: var(--md-color-blue) !important;
					text-decoration: underline;
					font-family: var(--md-font-family-helvetica-neue-bold);
					font-size: var(--md-font-size-small);
					line-height: 15px;
					white-space: nowrap;
					display: block;
				}
			}
		}
	}
}

@media screen and (min-width: 0px) and (max-width: 595px) {
	.news-container {
		.indicators {
			display: none;
		}

		.selected-news-container {
			margin-bottom: 40px;

			.news-label {
				>a {
					display: none;
				}
			}
		}

		.headline-news-component {
			display: none;
		}

		border: none;

		.control-label>a {
			color: var(--md-color-blue) !important;
			text-decoration: underline;
			font-family: var(--md-font-family-helvetica-neue-bold);
			font-size: var(--md-font-size-small);
			line-height: 15px;
			white-space: nowrap;
			display: block;
			float: right;
			padding: 15px;
		}

		.hide-label>a {
			margin-top: -42px;
		}

		.show-label>a {
			margin-top: -49px;
		}

		.dropdown-container {
			.dropdown-row {
				display: block;

				.component-title {
					display: none;
				}

				.dropdowns {
					display: block;
					width: 100%;

					.type-dropdown {
						width: 100%;
						margin-bottom: 16px;
					}

					.category-dropdown {
						width: 86%;
						float: right;
						margin-bottom: 16px;
					}

					.period-dropdown {
						display: none;
					}
				}

				.hide-label {
					display: none;
				}
			}

			.second-row {
				.second-row-dropdowns {
					.correlate-dropdown {
						width: 98.5%;
						margin-bottom: 14px;

						.type-heading {
							margin-left: unset;
						}
					}

					.update-button {
						width: 100%;
						margin-bottom: 14px;

						>button {
							width: 100%;
						}
					}
				}
			}
		}

		.indicators-mobile {
			display: block;
			margin-bottom: 14px;

			.indicator-text-mobile {
				font-family: var(--md-font-family-helvetica-neue-regular);
				font-size: var(--md-font-size-small);
				line-height: 14px;
				color: var(--md-color-dark-gray);
				text-align: center;
			}

			.indicator-pill-mobile {
				display: flex;
				white-space: nowrap;
				justify-content: space-between;
				margin-top: 15px;

				.icon-1 {
					width: 14px;
					height: 14px;
					background-color: var(--md-color-light-blue-variant);
					margin-right: 10.5px;
				}

				.icon-2 {
					width: 14px;
					height: 14px;
					background-color: var(--md-color-dull-blue);
					margin-right: 10.5px;
				}

				.icon-3 {
					width: 14px;
					height: 14px;
					background-color: var(--md-color-mid-blue);
					margin-right: 10.5px;
				}

				.icon-4 {
					width: 14px;
					height: 14px;
					background-color: var(--md-color-blue-solid);
					margin-right: 10.5px;
				}

				.icon-label {
					font-family: var(--md-font-family-helvetica-neue-regular);
					font-size: var(--md-font-size-small);
					line-height: 14px;
					color: var(--md-color-dark-gray);
					margin-right: 20.5px;
				}

				.last-label {
					margin-right: 0px;
				}
			}
		}
	}
}