.chartEventInfoContainer {
    pointer-events: none;
}

.chartPopover {
    position: absolute;
    z-index: 996;
    background-color: var(--md-color-light-white-background);
    box-shadow: 0 1px 5px 0 rgba(56, 81, 100, 0.5);
    padding: 13px 13px 9px;
    max-width: 170px;
}

.chartEventContainer {
    .row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;

        >span {
            line-height: var(--md-font-size-regular);
        }
    }
}