.chart {
    width: 100%;

    .no-data-container {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    img {
        width: 100%;
        height: inherit;
    }
}