.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;

    .modal-container {
        background-color: var(--md-color-white);
        width: 500px;
        max-width: 90%;
        max-height: 90%;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
        padding: 18px;
        position: relative;
        height: auto;
        overflow-y: initial;
        z-index: 99999;

        .modal-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            height: 32px;
            color: var(--md-color-black);
            font-size: 20px;
            letter-spacing: 0;
            line-height: 32px;

            .modal-title {
                height: 32px;
                width: 59px;
                font-family: var(--md-font-family-palatino-regular);
            }

            .close-button {
                cursor: pointer;
                margin-bottom: 3px;
            }
        }

        .modal-body {
            font-size: 16px;
            min-height: 70px;
        }

        .dismiss {
            display: flex;
            justify-content: center;

            button {
                width: 40%;
            }
        }

        .modal-footer {
            bottom: 0;
            left: 0;
            right: 0;
            height: 50px;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: center;

            button {
                margin: 10px
            }
        }
    }
}