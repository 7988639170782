.loading-wrapper {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    flex-direction: column;

    .progress-circular {
        align-items: center;
        display: inline-flex;
        justify-content: center;
        position: relative;
        vertical-align: middle;
        color: rgb(43, 109, 159) !important;

        >svg {
            width: 100%;
            height: 100%;
            margin: auto;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 0;
            transform: rotate(calc(-90deg));
        }
    }

    .underlay {
        color: rgba(0, 0, 0, .12);
        stroke: currentColor;
        z-index: 1;
    }

    .overlay {
        stroke: currentColor;
        transition: all 0.2s ease-in-out, stroke-width 0s;
        z-index: 2;
    }

    .size-x-small {
        height: 16px;
        width: 16px;
    }

    .size-small {
        height: 24px;
        width: 24px;
    }

    .size-default {
        height: 32px;
        width: 32px;
    }

    .size-large {
        height: 48px;
        width: 48px;
    }

    .size-x-large {
        height: 64px;
        width: 64px;
    }

    .indeterminate>svg {
        animation: progress-circular-rotate 1.4s linear infinite;
        transform-origin: center center;
        transition: all 0.2s ease-in-out;
    }

    .indeterminate .overlay {
        animation: progress-circular-dash 1.4s ease-in-out infinite;
        stroke-dasharray: 25, 200;
        stroke-dashoffset: 0;
        stroke-linecap: round;
    }

    @keyframes progress-circular-dash {
        0% {
            stroke-dasharray: 1, 200;
            stroke-dashoffset: 0px;
        }

        50% {
            stroke-dasharray: 100, 200;
            stroke-dashoffset: -15px;
        }

        100% {
            stroke-dasharray: 100, 200;
            stroke-dashoffset: -124px;
        }
    }

    @keyframes progress-circular-rotate {
        100% {
            transform: rotate(270deg);
        }
    }
}