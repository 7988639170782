#todays-performers-container {
	background: var(--md-color-white);
	width: 100%;
	box-sizing: border-box;

	.no-data-container {
		margin-top: 50px;
	}

	.header {
		margin-bottom: 7px;
		display: flex;
		justify-content: space-between;
		align-items: baseline;
	}

	.performersContainer {
		height: 165px;

		.performersDropDown {
			width: 100%;
			display: flex;
			justify-content: space-between;
			gap: 16px;
			box-sizing: border-box;

			.dropDownPerformer {
				width: 50%;
			}
		}

		.performersContentMain {
			.headerMain {
				padding-top: 4px;
				padding-bottom: 4px;
			}

			.commonRow {
				display: flex;
				justify-content: space-between;
				padding: 0 12px 0 2px;

				.commonCol {
					width: 33%;
					text-align: right;
					padding: 4px 8px 4px 0px;
				}

				.commonCol:first-child {
					text-align: left;
				}
			}

			.contentRow {
				border-top: 1px solid var(--md-color-divider-gray);
			}

			.tableHeader:not(:first-child) {
				text-align: right;
			}

			.tableContent {
				max-height: 85px;
				overflow-y: auto;
			}
		}
	}
}

@media screen and (min-width: 596px) and (max-width: 1023px) {
	#todays-performers-container {
		background: var(--md-color-white);
		width: 100%;
		.header {
			margin-bottom: 7px;
		}
		.performersContainer {
			gap: 22px;
		}
	}
}

@media screen and (min-width: 0px) and (max-width: 595px) {
	#todays-performers-container {
		padding-top: 11px;

		.performersContainer {
			height: 210px;
			gap: 9px;
			.performersContentMain {
				padding-bottom: 5px;
			}
		}

		.header {
			margin-bottom: 4px;
		}
	}
}

@media screen and (min-width: 0px) and (max-width: 480px) {
	.ellipsis {
		width: 80px;
	}
}
