.tabs {
	font-family: var(--md-font-family-helvetica-neue-bold);
	font-size: 16px;
	line-height: 19px;
	background: transparent;
	color: var(--md-color-black);

	.tab_list {
		color: var(--md-color-white);
		display: flex;
		padding-left: 0px;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
		-ms-overflow-style: -ms-autohiding-scrollbar;

		li {
			.tabHeaderText {
				display: flex;
				height: 40px;

				.tab_text {
					padding: 8px 12px 0px 8px;
				}
			}

			.tabHeaderDivider {
				border-right: 1px solid var(--md-color-blur-white);
			}
		}
	}

	.noBackground {
		border-bottom: none !important;
		background: transparent !important;
		margin-left: -40px;
	}

	.tab_list::-webkit-scrollbar {
		display: none;
	}

	.tab_list_item {
		font-family: var(--md-font-family-helvetica-neue-regular);
		font-size: 16px;
		line-height: 18px;
		text-align: center;
		color: var(--md-color-dark-blue);
		cursor: pointer;
		display: block;
		list-style: none;
		white-space: nowrap;
	}

	.tab_list_item::before {
		display: block;
		overflow: hidden;
		visibility: hidden;
	}

	.tab_list_active {
		font-size: 16px;
		text-align: center;
		font-weight: bolder;
		cursor: pointer;
		display: block;
		list-style: none;
		white-space: nowrap;
		font-family: var(--md-font-family-helvetica-neue-regular);
		color: var(--md-color-dark-blue-solid);
		border-bottom: 4px solid var(--md-color-dark-blue-solid);
	}

	.tab {
		font-size: 14px;
	}
}

@media only screen and (max-width: 767px) and (min-width: 375px) {
	.tabs {
		.tab_list {
			padding: 0px;
		}

		.noBackground {
			border-bottom: none;
			background: transparent;
			padding: 0px;
			margin-left: 0px;
		}
	}
}
