.option-details-container {
    #popover {
        min-width: 400px;
        z-index: 999;
        left: 10;
        font-size: 12px;
        color: var(--md-color-black);
        line-height: 16px;
        padding: 0px 0px 5px 2px;
        background: var(--md-color-light-white-background);
        box-shadow: -1px 2px 6px 2px rgba(0, 0, 0, 0.10), 1px 2px 6px 2px rgba(0, 0, 0, 0.10);
        pointer-events: none;
    }

    .option-details-label {
        display: flex;
        align-items: center;
    }
}