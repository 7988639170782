.markets-indices {
    font-family: var(--md-font-family-palatino-regular);
    width: 100%;

    .loader-wrapper {
        display: flex;
        align-items: center;
        min-height: 200px;
        justify-content: center;
    }

    .no-data-container {
        align-items: center;
        display: flex;
        min-height: 150px;
    }

    .tab-container {
        ul {
            border-bottom: 1px solid var(--md-color-divider-gray);
            margin-right: 10px;
            margin-top: 0;
        }

        ul>li {
            font-family: var(--md-font-family-palatino-regular);
        }
    }

    .indices-container {
        display: flex;

        .chart-container {
            width: 51%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 15px;

            .delayed-market-data {
                .label {
                    font-size: 11px;
                    line-height: 12px;
                    padding-top: 20px;
                    color: var(--md-color-light-grey);
                }
            }
        }

        .indices-table-container {
            width: 49%;

            .table-container {
                table {
                    font-size: 12px;
                    line-height: 18px;

                    thead {
                        tr {
                            th {
                                border-top: none;
                                padding: 10px 0 10px 0;

                                span {
                                    font-family: var(--md-font-family-helvetica-neue-medium);
                                    text-align: right;
                                    color: var(--md-color-black);
                                    font-size: 12px;
                                    line-height: 18px;
                                }
                            }
                        }

                        th:not(:first-child) {
                            text-align: right;
                        }
                    }

                    tbody {
                        td {
                            text-align: right;
                            padding: 10px 0 10px 0;

                            a {
                                font-family: var(--md-font-family-helvetica-neue-bold);
                                font-size: 12px;
                                line-height: 18px;
                                color: var(--md-color-blue);
                                text-align: left;
                            }

                            span {
                                font-size: 12px;
                                line-height: 15px;
                                font-family: var(--md-font-family-helvetica-neue-bold);
                            }
                        }
                    }

                    tbody tr:hover {
                        background-color: var(--md-color-light-blue);
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 0px) and (max-width: 595px) {
    .markets-indices {
        .indices-container {
            display: flex;
            flex-flow: column wrap;
            padding: 20px 0px;

            .chart-container {
                width: 100%;
                height: 50%;
            }

            .indices-table-container {
                width: 100%;

                .table-container {
                    table {
                        font-size: 12px;
                        line-height: 18px;

                        thead {
                            tr {
                                th {
                                    border-top: none;

                                    span {
                                        font-family: var(--md-font-family-helvetica-neue-medium);
                                        text-align: right;
                                        color: var(--md-color-black);
                                        font-size: 12px;
                                        line-height: 18px;
                                    }
                                }
                            }

                            th:not(:first-child) {
                                text-align: right;
                            }
                        }

                        tbody {
                            td {
                                text-align: right;

                                a {
                                    font-family: var(--md-font-family-helvetica-neue-bold);
                                    font-size: 12px;
                                    line-height: 18px;
                                    color: var(--md-color-blue);
                                }

                                span {
                                    font-size: 12px;
                                    line-height: 15px;
                                    font-family: var(--md-font-family-helvetica-neue-bold);
                                }
                            }

                            td:first-child {
                                text-align: left;
                            }
                        }

                        tbody tr:hover {
                            background-color: var(--md-color-light-blue);
                        }
                    }
                }
            }

            .delayed-market-data-indices {
                font-family: var(--md-font-family-helvetica-neue-regular);
                font-size: 11px;
                padding-top: 10px;
                color: var(--md-color-light-grey);
                height: 24px;
                width: 202px;
                letter-spacing: 0;
                line-height: 12px;
            }
        }

        .collapsible-section {
            padding-bottom: 25px;
        }
    }
}