.option-quote-bar {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	gap: 20px;

	.quote-info {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.quote-field {
			display: flex;
			flex-direction: column;
			gap: 10px;
			align-items: center;
			border-right: 1px solid var(--md-color-blur-white);
			width: 24.5%;
			margin-top: 15px;

			&:nth-of-type(4n) {
				border-right: none;
			}

			.value {
				display: flex;
				flex-direction: column;
				gap: 15px;
				align-items: center;
				padding: 5px;

				.label:not(.trade-price-label) {
					font-size: var(--md-font-size-large);
					font-family: var(--md-font-family-helvetica-neue-bold);
					letter-spacing: 0;
					line-height: 9.62px;
				}
			}
		}
	}
}

@media screen and (max-width: 596px) {
	.option-quote-bar {
		.quote-info {
			.quote-field {
				width: 48%;
			}
		}
	}
}
