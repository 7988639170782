.option-quote-container {
	.option-quote-container-header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 10px;
		margin: 30px 0;
		white-space: nowrap;

		.heading {
			line-height: 32px;
			width: 20%;
			text-align: left;
		}

		.quote-header-info {
			width: 80%;
			display: flex;
			margin-right: 10px;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			gap: 10px;

			.each-col {
				display: flex;

				.label,
				.value {
					color: var(--md-color-black);
					font-size: var(--md-font-size-regular);
					letter-spacing: 0.29px;
					line-height: 17px;

					&.option-symbol-name {
						white-space: pre;
					}
				}

				.value {
					font-family: var(--md-font-family-helvetica-neue-bold);
					font-weight: bold;
				}
			}

			.col-group {
				display: flex;
				gap: 10px;
			}
		}
	}

	.symbology-toggle {
		padding: 20px 0px;
		font-size: var(--md-font-size-small);

		.labelLink {
			color: var(--md-color-blue);
			font-family: var(--md-font-family-helvetica-neue-bold);
			font-weight: bold;
			width: fit-content;
			letter-spacing: 0;
			font-size: var(--md-font-size-small);
			line-height: 18px;
			text-decoration: underline;
		}
	}

	.options-symbology {
		color: var(--md-color-dark-gray);
		margin-bottom: 20px;

		.static-content {
			color: var(--md-color-dark-gray) !important;
			font-family: var(--md-font-family-helvetica-neue-medium);
			line-height: 15px;

			span {
				font-family: var(--md-font-family-helvetica-neue-medium);
			}
		}

		.oic-note,
		.decimal-point-note {
			font-family: var(--md-font-family-helvetica-neue-regular);
			font-size: var(--md-font-size-small);
			line-height: 14px;
		}

		.options-symbol {
			.options-symbol-header {
				font-size: var(--md-font-size-small);
				font-family: var(--md-font-family-helvetica-neue-medium);

				.options-symbol-title {
					font-family: var(--md-font-family-helvetica-neue-medium);
				}
			}

			.options-symbol-table-container {
				margin: initial;

				.options-symbol-table {
					color: var(--md-color-dark-gray);

					th,
					td {
						color: var(--md-color-dark-gray);

						span {
							color: var(--md-color-dark-gray);
							line-height: 14px;
						}

						&:first-of-type {
							font-family: var(--md-font-family-helvetica-neue-medium);
						}
					}
				}
			}
		}
	}

	.separator {
		margin-bottom: 20px;
		background-color: var(--md-color-dark-gray);
	}
}

@media screen and (min-width: 596px) and (max-width: 800px) and (min-device-width: 1024px) {
	.option-quote-container {
		.option-quote-container-header {
			white-space: normal;
		}
	}
}
@media screen and (min-width: 596px) and (max-width: 1250px) {
	.option-quote-container {
		.option-quote-container-header {
			align-items: baseline;

			.quote-header-info {
				width: 70%;
				flex-direction: column;
				align-items: baseline;
			}
		}
	}
}

@media screen and (max-width: 596px) {
	.option-quote-container {
		.separator {
			margin-bottom: 0;
		}

		.option-quote-container-header {
			flex-direction: column;
			width: 100%;
			align-items: flex-start;
			margin: 10px 0;

			.heading {
				width: initial;
			}

			.quote-header-info {
				flex-direction: column;
				align-items: flex-start;
				width: 100%;

				.each-col {
					.label,
					.value {
						font-size: var(--md-font-size-small);
						letter-spacing: 0.25px;
						line-height: 15px;
					}
				}

				.col-group {
					gap: 10px;
				}
			}
		}

		.collapsible-container {
			details {
				border: none;
				padding: 15px 0px;

				summary {
					.heading {
						font-size: var(--md-font-size-large);
					}
				}
			}
		}
	}
}
