#quote-new-header-tab {
    background: transparent;
}

@media screen and (min-width: 0px) and (max-width: 595px) {
    .quotes-news-mobile {
        .collapsible-section {
            padding-bottom: 25px !important;

            h4 {
                font-size: 16px !important;
            }

        }

        .collapsible-section:last-of-type {
            padding-bottom: 0;
        }
    }
}