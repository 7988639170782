.quote-body {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-top: 20px;
	// padding-bottom: 25px;

	.quote-col {
		width: 12%;

		.quote-label-key {
			text-align: left;
			width: 100%;

			span {
				font-family: var(--md-font-family-helvetica-neue-regular) !important;
				color: var(--md-color-dark-gray) !important;
			}
		}

		.mf-label-key {
			span {
				line-height: 27px;
			}
		}

		.mf-sublabel-key {
			margin-bottom: 2px;

			span {
				line-height: var(--md-font-size-small) !important;
				font-size: 11px;
			}
		}

		.mf-label-value {

			span {
				line-height: 10px;
			}
		}

		.quote-label-value {
			text-align: left;
			width: 100%;

			span {
				font-family: var(--md-font-family-helvetica-neue-bold) !important;
			}
		}
	}

	.quote-col:first-child {
		.quote-label-key {
			text-align: center;

			span {
				font-family: var(--md-font-family-helvetica-neue-regular) !important;
			}
		}

		.quote-label-value {
			text-align: center;
		}
	}

	.quote-col:not(:first-child) {
		width: 16%;
		border-left: 0.5px solid var(--md-color-scrollbar-gray);

		.quote-label-key {
			text-align: center;
			width: 100%;

			span {
				font-family: var(--md-font-family-helvetica-neue-regular) !important;
			}
		}

		.quote-label-value {
			text-align: center;
			width: 100%;

			span {
				font-family: var(--md-font-family-helvetica-neue-bold);
			}
		}
	}
}

div[data-attribute='true'] {
	.quote-body {
		margin-top: 13px;
		padding-bottom: 0px;
		border-bottom: none;

		.quote-mobile-view {
			width: 100%;

			.quote-first-row {
				display: flex;
				justify-content: space-between;
				width: 100%;
				padding: 8px 0px;

				.quote-col {
					width: 28%;
					border-left: none;
					margin-right: 7px;
					display: flex;
					flex-direction: column;

					.quote-label-key {
						text-align: center;
						margin-left: 0%;
						width: 100%;

						span {
							font-family: var(--md-font-family-helvetica-neue-regular) !important;
							color: var(--md-color-dark-gray) !important;
						}
					}

					.quote-label-value {
						text-align: center;
						margin-left: 0%;
						width: 100%;
						min-height: 20px;
						padding-top: 5px;

						span {
							font-family: var(--md-font-family-helvetica-neue-bold);
						}
					}
				}

				.quote-col:nth-child(2) {
					width: 35%;

					.quote-label-value {
						min-height: 20px;
						border-left: 0.5px solid var(--md-color-scrollbar-gray);
						border-right: 0.5px solid var(--md-color-scrollbar-gray);
					}
				}

				.quote-col:nth-child(3) {
					width: 37%;
				}
			}

			.quote-second-row {
				display: flex;
				justify-content: space-between;
				width: 100%;
				padding: 8px 0px;

				.quote-col {
					width: 28%;
					border-left: none;
					margin-right: 7px;
					display: flex;
					flex-direction: column;

					.quote-label-key {
						text-align: center;
						margin-left: 0%;
						width: 100%;

						span {
							font-family: var(--md-font-family-helvetica-neue-regular) !important;
							color: var(--md-color-dark-gray) !important;
						}
					}

					.quote-label-value {
						text-align: center;
						margin-left: 0%;
						width: 100%;
						min-height: 20px;
						padding-top: 5px;

						span {
							font-family: var(--md-font-family-helvetica-neue-bold);
						}
					}
				}

				.quote-col:nth-child(2) {
					width: 35%;

					.quote-label-value {
						min-height: 20px;
						border-left: 0.5px solid var(--md-color-scrollbar-gray);
						border-right: 0.5px solid var(--md-color-scrollbar-gray);
					}
				}

				.quote-col:nth-child(3) {
					width: 37%;
				}
			}
		}
	}
}