.news-content-container {
    .news-content {
        .provider {
            margin-top: 9px;
            padding-bottom: 8px;

            .dividerProvider {
                font-size: 12px;
                line-height: 12px;
                display: inline-block;
                color: var(--md-color-gray);
                position: relative;
                font-family: var(--md-font-family-helvetica-neue-regular);
                letter-spacing: 0;
                margin: 0px 14px;
            }
        }

        border-bottom: 1px solid var(--md-color-divider-gray);
    }

    .news-content:not(:first-child) {
        padding-top: 11px;
    }
}