#glossary-container {
	font-family: var(--md-font-family-palatino-regular);
	background: var(--md-color-white);
	border: 1px solid var(--md-color-blur-white);
	overflow-x: auto;

	.header {
		margin-bottom: 12px;
		font-family: inherit;
		line-height: 36px;
		font-size: 32px;
		color: var(--md-color-black);
	}

	.glossary-main-container {
		.glossary-searchbox {
			display: flex;
			position: relative;
			padding: 20px 0px 15px 0px;
			align-items: center;
			width: 281px;
			font-family: inherit;
			font-size: 12px;
			line-height: 20px;
			color: var(--md-color-dark-gray);

			.search-icon {
				position: absolute;
				color: var(--md-color-white);
				z-index: 1;
				display: inline-block;
				padding-left: 15px;
				font-size: 18px;

				svg {
					font-weight: 300;
					height: 18px;
					width: 18px;
				}
			}

			.searchbox {
				padding-left: 10px;
				width: 100%;
				height: 36px;
				border-radius: 3px;
				font-family: var(--md-font-family-helvetica-neue-regular);
				font-size: 12px;
				line-height: 20px;
				color: var(--md-color-dark-gray);
				border: 1px solid var(--md-color-blur-white);
				padding-top: 0px;
			}

			::placeholder {
				font-family: var(--md-font-family-helvetica-neue-regular);
				font-size: 12px;
				line-height: 20px;
				color: var(--md-color-dark-gray);
			}

			.cancelButton {
				right: 40px;
				position: absolute;
				font-size: 14px;
				line-height: 51px;
				text-align: center;
				z-index: 2;
				height: 25px;
				width: 48px;
				cursor: pointer;
			}

			.clearButton {
				right: 0px;
				position: absolute;
				font-size: 14px;
				line-height: 51px;
				text-align: center;
				background: var(--md-color-blue);
				z-index: 2;
				height: 40px;
				width: 48px;
				cursor: pointer;
			}
		}

		.glossary-searchbox input[type='text']:focus {
			border: 2px solid var(--md-color-dark-gray);
			outline: var(--md-color-dark-gray);
		}

		.glossary-instruction {
			font-family: var(--md-font-family-helvetica-neue-regular);
			font-size: 12px;
			line-height: 18px;
			margin-top: 5px;
			color: var(--md-color-light-grey-hard);

			.instruction {
				margin-top: 2px;
			}
		}

		.glossary-terms-shortname {
			font-family: inherit;
			font-weight: bold;
			font-size: 20px;
			line-height: 26px;
			display: inline-block;
			width: 100%;
			border-bottom: 1px solid var(--md-color-divider-gray);
			margin-top: 10px;
			cursor: pointer;

			a {
				padding: 7px;
				color: var(--md-color-dark-gray);
			}

			a:first-child {
				padding-left: 0;
			}
		}

		.glossary-term-container {
			margin-top: 14px;

			.glossary-term-list {
				margin: 0px auto;
				overflow-y: auto;
				max-height: 400px;
				padding-right: 8px;

				.glossary-term {
					.term-defination {
						font-family: var(--md-font-family-helvetica-neue-regular);
						font-size: 12px;
						line-height: 17px;
						margin-top: 9px;
						padding-bottom: 8px;

						span {
							color: var(--md-color-dark-gray);
						}
					}

					.headline {
						font-family: inherit;
						font-size: 14px;
						line-height: 19px;
						margin-bottom: 9px;

						span {
							font-family: var(--md-font-family-palatino-regular) !important;
							font-size: 14px;
						}
					}

					border-bottom: 1px solid var(--md-color-divider-gray);
				}

				.glossary-term:not(:first-child) {
					padding-top: 11px;
				}
			}
		}

		.glossary-pagination {
			font-family: var(--md-font-family-helvetica-neue-regular);
			font-size: 12px;
			line-height: 18px;
			color: var(--md-color-light-grey-hard);
			margin-top: 20px;
			text-align: center;
		}
	}
}
@media screen and (min-width: 596px) and (max-width: 1023px) {
	.headline-glossary-container {
		padding: 11px 22px 20px 20px;

		.header {
			margin-bottom: 7px;
		}

		.glossary-main-container {
			.glossary-searchbox {
				width: 50%;
			}

			.glossary-term-container {
				margin-top: 12px;

				.glossary-term-list {
					padding-right: 5px;
					max-height: 500px;
				}
			}
		}
	}
}

@media screen and (min-width: 0px) and (max-width: 595px) {
	#glossary-container {
		padding: 12px 20px 18px 20px;
		position: absolute;

		.header {
			margin-bottom: 6px;
		}

		.glossary-main-container {
			.glossary-searchbox {
				width: 100%;
				box-sizing: border-box;
				padding-right: 5px;

				.clearButton {
					right: 5px;
				}
			}

			.searchbox {
				width: 100%;
			}

			.glossary-term-container {
				margin-top: 11px;

				.glossary-term-list {
					padding-right: 5px;
					max-height: 500px;
				}
			}
		}

		.glossary-terms-shortname {
			width: 100%;

			.terms-shortname-dropDown {
				width: 100%;
				// min-width: 280px;
				margin: 20px 0px 20px 0px;
			}
		}
	}
}
