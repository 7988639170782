.filterTagContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--md-color-white);
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.10);
  border-radius: 3px;
  min-width: 100px;
  max-width: 200px;
  padding: 5px 10px;
  line-height: 18px;
  height: 36px;

  .tag-container {
    display: flex;

    .tag-box {
      height: 14px;
      width: 14px;
      margin-top: 3px;
    }

    .header {
      font-family: var(--md-font-family-helvetica-neue-regular);
      font-size: 12px;
      color: var(--md-color-dark-gray);
      line-height: 20px;
      padding: 0px 5px;
    }
  }

  .closeButton {
    text-align: right;
    display: inline-flex;
    cursor: pointer;
  }
}

@media screen and (min-width: 0px) and (max-width: 595px) {
  .filterTagContainer {
    .tag-container {
      .header {
        padding-bottom: 0px;
      }
    }
  }
}