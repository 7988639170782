.back-link-container {
	margin-bottom: 16px;

	.back-link {
		color: var(--md-color-blue);
		font-family: var(--md-font-family-helvetica-neue-bold);
		font-size: var(--md-font-size-small);
		font-weight: bold;
		letter-spacing: 0;
		line-height: 18px;
		cursor: pointer;

		.back-icon {
			text-decoration: none;
			margin-right: 5px;
		}

		span {
			text-decoration: underline;
		}
	}
}
