.customize-watchlist-view {
    display: flex;
    flex-direction: column;
    align-items: center;

    .customize-watchlist-container {
        width: 60%;
        min-width: 60%;

        .message-box {
            margin-top: 20px;
        }

        .separator {
            margin-bottom: 15px;
        }

        .customize-watchlist-content {
            padding: 20px;
            border: 1px solid var(--md-color-blur-white);
            background: var(--md-color-white);

            .customize-watchlist-header {
                margin-bottom: 10px;
                color: var(--md-color-black);
                font-family: var(--md-font-family-palatino-regular);
                font-size: 36px;
                letter-spacing: 0;
                line-height: 32px;
            }

            .display-option {
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                color: var(--md-color-black);
                font-family: var(--md-font-family-helvetica-neue-regular);
                font-size: var(--md-font-size-small);
                font-weight: 500;
                letter-spacing: 0.25px;
                line-height: 15px;

                .radio-label {
                    line-height: 14px;
                    margin-left: 0;
                }

                .display-option-label {
                    margin-right: 10px;
                    line-height: 15px;
                    font-family: var(--md-font-family-helvetica-neue-medium);
                }

                .display-type-container {
                    display: flex;
                    align-items: center;
                    gap: 20px;

                    .radio-button {
                        margin-right: 10px;
                    }
                }
            }

            .watchlist-limit-message,
            .add-security-header,
            .display-option-label {
                color: var(--md-color-black);
                font-family: var(--md-font-family-helvetica-neue-medium);
                font-size: var(--md-font-size-small);
                font-weight: 500;
                letter-spacing: 0;
                line-height: 18px;
            }

            .search-container {
                display: flex;
                align-items: center;

                .add-security-header {
                    font-family: var(--md-font-family-helvetica-neue-medium);
                    margin-right: 10px;
                }

                label {
                    margin-right: 10px;
                    color: var(--md-color-black);
                    font-family: var(--md-font-family-helvetica-neue-regular);
                    font-size: var(--md-font-size-small);
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 18px;
                }
            }

            .customize-watchlist-table-container {
                width: 100%;
                max-height: 42vh;
                overflow-y: auto;
                margin: 10px 0;

                &.loading {
                    overflow-y: hidden;

                    .loading-wrapper {
                        min-height: 39vh;
                    }
                }

                a {
                    text-decoration: none;
                    cursor: pointer;
                    line-height: 18px;
                    max-width: 400px;


                    &.labelLink-m {
                        font-size: var(--md-font-size-small);
                        font-family: var(--md-font-family-helvetica-neue-bold);
                        color: var(--md-color-blue);
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

                .empty-watchlist {
                    text-align: center;
                    color: var(--md-color-gray);
                    margin-top: 20px;
                }

                .delete-button {
                    height: 15px;
                    width: 15px;
                    cursor: pointer;
                }

                th {
                    padding-left: 0px;

                    span {
                        font-family: var(--md-font-family-helvetica-regular);
                        font-weight: 500;
                    }
                }

                td {
                    color: var(--md-color-dark-gray);
                    font-family: var(--md-font-family-helvetica-neue-regular);
                    font-size: var(--md-font-size-small);
                    letter-spacing: 0;
                    line-height: 14px;
                    text-align: left;
                    padding-left: 0px;

                    :first-child {
                        text-align: left;
                    }
                }
            }

            .customize-watchlist-count-container {
                color: var(--md-color-light-grey-hard);
                font-family: var(--md-font-family-helvetica-neue-regular);
                font-size: 11px;
                letter-spacing: 0;
                line-height: 18px;
                text-align: center;
                margin-top: 20px;
            }

            .no-items-added {
                display: flex;
                justify-content: center;
                font-family: var(--md-font-family-helvetica-neue-regular);
                font-size: var(--md-font-size-small);
                font-weight: 500;
                margin: 20px 0;
            }

            .watchlist-limit-message {
                margin-bottom: 5px;
            }

            .button-container {
                display: flex;
                justify-content: center;
                margin-top: 30px;
                gap: 20px;

                button {
                    cursor: pointer;
                    height: 36px;
                    width: 160px;
                    font-size: var(--md-font-size-small);
                    font-family: var(--md-font-family-helvetica-neue-regular);

                    &.cancel-button {
                        background-color: var(--md-color-white);
                        color: var(--md-color-dark-gray);
                        border: 1px solid var(--md-color-blue-variant);
                    }

                    &.submit-button {
                        color: var(--md-color-white);
                        border: 1px solid var(--md-color-blue-variant);
                        background-color: var(--md-color-chart-border);
                    }
                }

            }
        }
    }
}

@media screen and (min-width: 0px) and (max-width: 1024px) {
    .customize-watchlist-view {
        .customize-watchlist-container {
            .customize-watchlist-content {
                .customize-watchlist-table-container {
                    max-height: 40vh;
                    margin: 20px 0;

                    a {
                        max-width: 125px;
                    }
                }
            }

        }
    }
}

@media screen and (min-width: 0px) and (max-width: 595px) {
    .customize-watchlist-view {
        .customize-watchlist-container {
            width: 100%;

            .customize-watchlist-content {
                padding: 17px;

                .searchbox-container {
                    .textbox {
                        min-width: initial;
                        max-width: 150px;
                    }

                    .search-button {
                        width: initial;
                        padding-left: 13px;
                    }
                }

                .customize-watchlist-table-container {
                    tr {
                        height: 48px;

                        td {
                            min-width: initial;

                            :first-child {
                                text-align: left;
                                max-width: 120px;
                            }
                        }
                    }
                }

                .customize-watchlist-header {
                    font-size: var(--md-font-size-extra-large)
                }

                .display-option {
                    flex-direction: column;
                    align-items: baseline;
                    gap: 10px;

                    .radio-label {
                        color: var(--md-color-dark-gray);
                    }

                    .display-type-container {
                        flex-direction: row;
                        justify-content: space-between;
                    }
                }

                .search-container {
                    flex-direction: column;
                    align-items: baseline;
                    gap: 10px;
                }

                .button-container {
                    button {
                        width: 120px;
                    }
                }
            }
        }
    }
}