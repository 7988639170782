.chart-settings {
    display: flex;
    justify-content: space-between;
    width: 98%;
    margin-top: 15px;
    border-bottom: 1px solid var(--md-color-divider-gray);
    padding: 10px;
    border-top: 1px solid var(--md-color-divider-gray);

    .chart-time-frames {
        display: flex;
        width: 50%;
        justify-content: space-between;
        font-family: var(--md-font-family-helvetica-neue-regular);
        font-size: 16px;
        color: var(--md-color-dark-blue);

        a {
            cursor: pointer;
        }

        .labelLink-active {
            font-family: var(--md-font-family-helvetica-neue-bold);
            color: var(--md-color-blue) !important;
            border-bottom: 4px solid var(--md-color-blue);
            cursor: pointer;
        }
    }

    .chart-types {
        margin-left: 5px;

        button {
            border: none;
            padding: 0px 3px 5px 5px;
            margin: 0px 2px;
            height: 28px;
            color: var(--md-color-black);

            &.secondary {
                background-color: transparent;

                &:hover {
                    background-color: transparent;
                }
            }
        }

        .active-chart-type {
            font-family: var(--md-font-family-helvetica-neue-bold);
            border-bottom: 4px solid var(--md-color-blue);
        }
    }
}

.chart-events-settings {
    display: flex;
    justify-content: space-between;
    width: 98%;
    margin: 10px 5px 10px 0px;
    padding-bottom: 5px;
    border-bottom: 1px solid var(--md-color-divider-gray);
    padding-left: 10px;

    .dropDownMain {
        width: 25%;
    }

    .chart-events {
        display: flex;

        button {
            font-family: var(--md-font-family-helvetica-neue-medium);
            font-size: 12px;
            border: none;
            color: var(--md-color-black);
            padding: 5px 20px 5px 0px;
            display: flex;

            &.secondary {
                background-color: transparent;
                color: var(--md-color-black);

                &:hover {
                    background-color: transparent;
                }
            }
        }

        svg {
            margin-right: 5px;
        }

        #popoverOverlay {
            min-width: 265px;
            height: auto;
            z-index: 999;
            font-size: 12px;
            color: var(--md-color-black);
            line-height: 16px;
            padding: 0px 0px 5px 2px;
            background: var(--md-color-white);
            box-shadow: -1px 2px 6px 2px rgba(0, 0, 0, 0.10), 1px 2px 6px 2px rgba(0, 0, 0, 0.10);

            span {
                white-space: normal;
            }
        }
    }
}

@media screen and (min-width: 0px) and (max-width: 595px) {

    .chart-settings {
        width: 100%;
        border-bottom: none;
        padding-left: 0px;

        .chart-settings-list {
            width: 100%;

            .chart-settings-controls {
                width: 100%;
                padding: 10px 0px;

                a {
                    float: right;
                    padding: 0px 10px 15px 0px;
                    font-family: var(--md-font-family-helvetica-neue-bold);
                    font-size: 12px;
                    line-height: 18px;
                    color: var(--md-color-blue);
                    text-decoration: underline;
                    cursor: pointer;
                }
            }

            .chart-settings-time-frames {
                width: 100%;
                // padding: 10px 0px;
            }

            .chart-settings-type {
                width: 100%;
                padding: 10px 0px;
            }

            .chart-settings-events {
                width: 100%;
                padding: 10px 0px;
            }

            button {
                width: 100%;
                display: flex;
                justify-content: space-between;
                color: var(--md-color-dark-gray);
                font-family: var(--md-font-family-helvetica-neue-regular);
                font-size: var(--md-font-size-small);
                padding: 12px 18px 0px 12px;
                border: 1px solid var(--md-color-blur-white);
                background-color: var(--md-color-white);
                position: relative;
                border-radius: 1px;
                outline: none;
                height: 39px;
                margin-top: 10px;
            }

            button:focus {
                border: 2px solid var(--md-color-dark-yellow);
            }
        }

        .chart-time-frames {
            display: flex;
            width: 35%;
            justify-content: space-between;
            font-family: var(--md-font-family-helvetica-neue-regular);
            font-size: 16px;
            color: var(--md-color-dark-blue);

            a {
                cursor: pointer;
            }

            .labelLink-active {
                font-family: var(--md-font-family-helvetica-neue-bold);
                color: var(--md-color-blue) !important;
                border-bottom: 4px solid var(--md-color-blue);
                cursor: pointer;
            }
        }

        .chart-types {
            margin-left: 5px;

            button {
                border: none;
                padding: 0px 3px 5px 5px;
                margin: 0px 5px;
                height: 28px;
                color: var(--md-color-black);

                &.secondary {
                    background-color: transparent;

                    &:hover {
                        background-color: transparent;
                    }
                }
            }

            .active-chart-type {
                font-family: var(--md-font-family-helvetica-neue-bold);
                border-bottom: 4px solid var(--md-color-blue);
            }
        }
    }

    .chart-events-settings-controls {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 5px;
        border-bottom: none;

        .dropDownMain {
            width: 100%;
        }

        .chart-events {
            display: flex;

            button {
                font-family: var(--md-font-family-helvetica-neue-medium);
                font-size: 12px;
                border: none;
                color: var(--md-color-black);
                padding: 5px 20px 5px 0px;
                display: flex;

                &.secondary {
                    background-color: transparent;
                    color: var(--md-color-black);

                    &:hover {
                        background-color: transparent;
                    }
                }
            }

            svg {
                margin-right: 5px;
            }

            #popoverOverlay {
                min-width: 265px;
                height: auto;
                z-index: 999;
                font-size: 12px;
                color: var(--md-color-black);
                line-height: 16px;
                padding: 0px 0px 5px 2px;
                background: var(--md-color-white);
                box-shadow: -1px 2px 6px 2px rgba(0, 0, 0, 0.10), 1px 2px 6px 2px rgba(0, 0, 0, 0.10);

                span {
                    white-space: normal;
                }
            }
        }
    }

    .chart-events-ddl {
        padding: 0px;
    }
}