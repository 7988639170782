.options-chain {
	display: flex;
	flex-direction: column;
	padding: 20px 0;
	border-top: 1px solid var(--md-color-gray);

	.loading-wrapper {
		padding: 20px 0;
	}

	.options-chain-header-row {
		display: flex;
		justify-content: space-between;
		margin-bottom: 10px;
		align-items: flex-start;

		.label {
			margin-right: 10px;
			color: var(--md-color-black);
			font-family: var(--md-font-family-helvetica-neue-medium);
			font-size: var(--md-font-size-small);
			font-weight: 500;
			letter-spacing: 0;
			line-height: 18px;
		}

		.options-header {
			font-size: 20px;
			width: 25%;
			font-weight: bold;

			.heading {
				text-align: left;
				line-height: 32px;
			}
		}

		.options-controls {
			width: 75%;
			display: flex;
			gap: 5px;
			justify-content: space-between;
			white-space: nowrap;

			.display-type-container {
				width: 33%;

				div.dropDownMain {
					width: 70%;
				}
			}

			.expiry-date-container {
				width: 43%;

				div.dropDownMain {
					width: 63%;
				}
			}

			.is-adjusted-strikes-container {
				margin-right: 0;
				flex-wrap: wrap;
				div:first-child {
					padding-bottom: 1%;
				}
				.labelLink {
					color: var(--md-color-blue);
					font-family: var(--md-font-family-helvetica-neue-bold);
					font-size: var(--md-font-size-small);
					text-decoration: underline;
				}

				.label {
					font-family: var(--md-font-family-helvetica-neue-regular);
					line-height: 14px;
				}
			}

			>div {
				display: flex;
				align-items: center;

				label {
					margin-right: 8px;
				}
			}
		}
	}

	.options-table {
		display: flex;
		gap: 10px;
		border-collapse: collapse;
		width: 100%;
		border: 1px solid #ccc;

		th,
		td {
			border: 1px solid #ccc;
			padding: 8px;
			text-align: left;
		}

		th {
			background-color: #f2f2f2;
		}

		tbody tr:nth-child(odd) {
			background-color: #f2f2f2;
		}
	}

	.adjusted-options {
		.adjusted-options-header {
			color: var(--md-color-red);
			font-family: var(--md-font-family-helvetica-neue-regular);
			font-size: var(--md-font-size-regular);
			font-weight: 500;
			padding: 10px;
			letter-spacing: 0;
			line-height: 17px;
			border-bottom: 1px solid var(--md-color-divider-gray);
		}
	}

	.indicator-container {
		margin-top: 15px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.in-the-money-label {
			padding: 7px;
			color: var(--md-color-dark-gray);
			font-family: var(--md-font-family-helvetica-neue-regular);
			font-size: var(--md-font-size-small);
			letter-spacing: 0;
			line-height: 14px;
			text-align: center;
			box-sizing: border-box;
			border: 1px solid var(--md-color-divider-gray);
			background-color: var(--md-color-light-white-background);
		}

		.delay-info {
			margin-right: 10px;
		}
	}
}

@media screen and (min-width: 0) and (max-width: 1024px) {
	.options-chain {
		.options-chain-header-row {
			align-items: initial;
			flex-direction: column;
			gap: 15px;

			.options-header {
				width: 100%;
			}

			.options-controls {
				width: 90%;

				.display-type-container {
					width: 31%;
				}

				.expiry-date-container {
					width: 43%;
				}
			}
		}

		.desktop-view {
			padding: 10px 0;
		}
	}
}

@media screen and (max-width: 596px) {
	.options-chain {
		.options-chain-header-row {
			flex-direction: column;
			align-items: flex-start;
			// padding: 0 10px;

			.options-header {
				width: initial;
			}

			.options-controls {
				width: 100%;
				gap: 10px;
				flex-direction: column;
				align-items: flex-start;

				.display-type-container,
				.expiry-date-container {
					width: 100%;
					justify-content: flex-end;

					div.dropDownMain {
						// width: 230px;
						width: 65%;
					}
				}

				.is-adjusted-strikes-container {
					display: flex;
					justify-content: space-between;
					width: 100%;

					>div:first-child {
						padding-left: 5px;
					}
				}
			}
		}

		.indicator-container {
			flex-direction: column;
			align-items: flex-start;
			gap: 10px;
			// padding: 0 10px;
		}
	}
}