#chart-indices-container {
    .chart-indices-dropdown {
        display: flex;
        justify-content: space-between;
        width: 25%;
        margin: 10px 0px;
        padding-left: 10px;

        .dropDownMain {
            span {
                font-family: var(--md-font-family-helvetica-neue-regular);
                font-size: 12px;
                line-height: 18px;
            }

            .basicDropDownContainer {
                margin-left: 15px;
            }
        }
    }

    .modal-overlay {
        background-color: transparent;
    }
}

@media screen and (min-width: 0px) and (max-width: 595px) {
    #chart-indices-container {

        .chart-indices-dropdown {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin: 10px 0px;
            padding-left: 0px;

            .dropDownMain {
                .basicDropDownContainer {
                    margin-left: 0px;
                }
            }
        }
    }
}