#news-by-category-container {
	background-color: --md-color-white;

	.newsContainer {
		.newsDropDownContainer {
			display: flex;

			.newsDropDown {
				padding-right: 18px;
			}
		}

		.newsDropDown {
			width: 100%;
		}

		.news-by-category-main {
			margin-top: 23px !important;
			max-height: 330px;
			overflow-y: auto;

			.news-content-container {
				margin-bottom: 10px;
			}

			.show-more-container {
				display: flex;
				flex-direction: column;
				justify-content: center;

				.show-more-button {
					margin: auto;
					padding-bottom: 0px !important;
					color: var(--md-color-blue);
					font-size: 12px;
					background-color: var(--md-color-white);
					font-weight: bold;
					letter-spacing: 0;
					line-height: 15px;
					text-align: center;
				}

				.show-more-records {
					padding-top: 7px;
					font-family: var(--md-font-family-helvetica-neue-regular);
					color: var(--md-color-light-grey-hard);
					font-size: 12px;
					letter-spacing: 0;
					line-height: 18px;
					text-align: center;
				}
			}

			.loader-container {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 330px;
			}

			.no-data {
				height: 330px;
			}
		}
	}
}

@media screen and (min-width: 596px) and (max-width: 1023px) {
	#news-by-category-container {
		padding: 11px 22px 20px 20px;

		.newsContainer {
			.newsDropDown {
				width: 50%;
			}

			.news-by-category-main {
				margin-top: 12px;
				max-height: 190px;
			}
		}

		.loader-container,
		.no-data {
			height: 190px !important;
		}
	}
}

@media screen and (min-width: 0px) and (max-width: 595px) {
	#news-by-category-container {
		padding: 12px 20px 18px 20px;

		.newsContainer {
			.newsDropDownContainer {
				display: flex;
				flex-flow: row wrap;

				.newsDropDown {
					margin-right: 16px;
					padding-right: 0px;
					max-width: 40%;
				}

				.newsDropDown:last-child {
					padding-top: 16px;
					min-width: 85%;
				}
			}

			.news-by-category-main {
				margin-top: 11px;
				max-height: 250px;
			}
		}

		.loader-container,
		.no-data {
			height: 250px !important;
		}
	}
}