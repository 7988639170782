.table-container {
  table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
    margin: 10px 0;

    th {
      border-bottom: 1px solid var(--md-color-divider-gray);

      span {
        font-family: var(--md-font-family-helvetica-neue-medium);
      }

    }

    th,
    td {
      padding: 10px;
      text-align: left;
    }

    th,
    tr {
      height: 18px;
      color: var(--md-color-black);
      font-family: var(--md-font-family-helvetica-neue-regular);
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 18px
    }

    tr {
      font-weight: normal;

      &.show-vertical-border {

        td,
        th {
          border-right: 1px solid var(--md-color-divider-gray);
        }

        td:first-of-type,
        th:first-of-type {
          border-left: 1px solid var(--md-color-divider-gray);
        }
      }

      &.show-horizontal-border {

        td,
        th {
          border-bottom: 1px solid var(--md-color-divider-gray);
          border-top: 1px solid var(--md-color-divider-gray);
        }
      }
    }
  }

  .pagination-container {
    display: flex;
    justify-content: space-between;

    .pagination-info {
      font-size: 12px;
      height: 18px;
      letter-spacing: 0;
      color: #7D7D7D;
      font-family: var(--md-font-family-helvetica-neue-regular);
    }

    .pagination-icons {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      svg {
        height: 6.3px;
        width: 11.1px;
        margin-top: 1px;
      }

      a {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0 8px;
        text-decoration: none;
        color: var(--md-color-black);
        transition: color 0.3s;
        font-family: var(--md-font-family-helvetica-neue-regular);
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;

        .dash-icon {
          height: 3px;
          width: 19px;
          border-radius: 1px;
          background-color: var(--md-color-blur-white);
        }

        &:hover {
          color: var(--md-color-blue);
        }

        &.active {
          font-family: var(--md-font-family-helvetica-neue-bold);
          text-decoration: underline;

          .dash-icon {
            background-color: var(--md-color-black);
          }
        }

        &.disabled {
          color: var(--md-color-scrollbar-gray);
          cursor: default;

          svg g {
            stroke: var(--md-color-gray);
          }
        }


      }
    }
  }
}

@media screen and (min-width: 0px) and (max-width: 1024px) {
  .table-container {
    .pagination-container {
      flex-direction: column-reverse;

      .pagination-info {
        margin-top: 15px;
        text-align: center;
      }
    }
  }
}