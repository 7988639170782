.snapshot-header-container {
	display: flex;
	justify-content: space-between;
	align-items: baseline;

	.name-container {
		.company-name {
			font-size: 24px;
			color: var(--md-color-black);
			font-family: var(--md-font-family-palatino-regular);
			line-height: 32px;
		}

		.symbol-type {
			color: var(--md-color-dark-gray);
			font-family: var(--md-font-family-palatino-regular);
			font-size: 18px;
			letter-spacing: 0;
			line-height: var(--md-font-size-extra-jumbo-large);
			white-space: nowrap;
		}
	}

	.watchlist-label-container {
		.watchlist-toast-container {
			.message-box {
				margin-top: -25px;
				position: absolute;
				white-space: nowrap;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}

		.watchlist-label {
			display: flex;
			justify-content: end;
			align-items: center;
			white-space: nowrap;

			.watchlist-icon {
				cursor: pointer;
			}

			>a {
				color: var(--md-color-blue);
				font-family: var(--md-font-family-helvetica-neue-bold);
				font-size: 12px;
				line-height: 15px;
				text-decoration: underline;
				float: right;
				margin-left: 9px;
			}

			.disabled {
				cursor: default;
				color: var(--md-color-disable-grey);
			}
		}

		.watchlist-alert-container {
			div[data-class='watchlist-alert'] {
				.modal-overlay {
					background-color: unset;
					position: static;

					.modal-container {
						padding: 9px 12px 12px 12px;
						width: 330px;
						position: absolute;
						left: 43%;

						.modal-header {
							margin: 0px;
							height: 22px;

							.heading {
								font-size: 14px;
								line-height: 19px;
							}
						}

						.modal-body {
							min-width: 65px;
						}

						.modal-footer {
							gap: 10px;
							padding: 0px;
							height: unset;

							button {
								margin: 0px;
								width: 50%;
								height: 36px;
								white-space: nowrap;
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: 0px) and (max-width: 595px) {
	.snapshot-header-container {
		.name-container {
			.company-name {
				font-size: var(--md-font-size-extra-large);
				line-height: var(--md-font-size-extra-jumbo-large);
			}

			.symbol-type {
				font-size: var(--md-font-size-large);
			}
		}

		.watchlist-label-container {
			.watchlist-toast-container {
				.message-box {
					margin-top: -10px;
					white-space: break-spaces;
					width: 60%;
					z-index: 3;
				}
			}

			.watchlist-label {
				>a {
					display: none;
				}
			}
		}
	}
}