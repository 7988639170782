.disabled {
	pointer-events: none;
}

.portfolio-holdings-container {
	.loader-container {
		margin: 20px 0;
	}

	.back-button {
		color: var(--md-color-blue);
		display: flex;
		gap: 5px;
		font-family: var(--md-font-family-helvetica-neue-medium);
		font-size: var(--md-font-size-small);
		line-height: 18px;
		font-weight: bold;

		.back-link {
			color: inherit;
			text-decoration: underline;
			text-decoration-skip-ink: none;
			cursor: pointer;
		}
	}

	.search-container {
		display: flex;
		gap: 20px;
		margin: 20px 0px;
		align-items: center;

		input.textbox {
			min-width: 250px;
		}
	}

	.transactions-container {
		max-height: 220px;
		overflow-y: auto;

		.common-row {
			display: flex;
			justify-content: space-between;
			padding: 10px 10px 10px 0;
			border-top: 1px solid var(--md-color-divider-gray);
			gap: 17px;
			align-items: center;

			span.label {
				height: auto;
			}

			> *:not(:first-child) {
				text-align: right;
				width: 12%;
			}

			> *:first-child {
				text-align: left;
				width: 15%;
			}

			> *:nth-child(2) {
				text-align: left;
				width: 5%;
			}

			> *:nth-child(3) {
				width: 21%;
			}

			> *:last-child {
				width: 5%;
			}

			.labelLink {
				color: var(--md-color-black);
			}

			.click-icon {
				padding: 0px;
				margin: 0px;
				border: none;
				background: none;
				cursor: pointer;
			}

			input[type='date']::-webkit-calendar-picker-indicator {
				width: 20px;
				height: 20px;
				margin-left: 2px;
				cursor: pointer;
			}

			input[type='date']::-webkit-datetime-edit {
				text-transform: uppercase;
			}

			input[data-error='true'] {
				border: 1px solid var(--md-color-red);
			}

			input.textbox {
				text-align: right;
				padding-right: 5px;
			}

			button {
				border: none;
				background: none;
				margin: 0;
				padding: 0;
				cursor: pointer;
			}

			input[name='fees']::-webkit-outer-spin-button,
			input[name='fees']::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			input[name='fees'] {
				-moz-appearance: textfield;
				padding-right: 10px;
			}
		}

		.highlight-row {
			border: 1px solid var(--md-color-red);
		}

		.last-row {
			border-top: 1px solid var(--md-color-dark-gray);
		}

		.contentRow {
			padding-top: 8px;
			padding-right: 0px;
			margin-right: 11px;
			border-top: 1px solid var(--md-color-divider-gray);
		}

		.tableHeader:not(:first-child) {
			text-align: right;
		}
	}
}

@media screen and (min-width: 1023px) {
	.portfolio-holdings-container {
		.transactions-container {
			.common-row {
				.labelLink {
					max-width: 100px !important;
				}
			}
		}
	}
}
@media screen and (min-width: 596px) and (max-width: 1023px) {
	.portfolio-holdings-container {
		.transactions-container {
			padding-right: 15px;

			.common-row {
				input[type='date'] {
					background: unset;
				}
				.labelLink {
					max-width: 90px !important;
				}
			}
		}
	}
}

@media screen and (min-width: 0px) and (max-width: 595px) {
	.portfolio-holdings-container {
		.search-container {
			padding: 8px 0px;
			flex-direction: column;
			gap: 9px;
			border-bottom: 1px solid var(--md-color-divider-gray);
			margin: unset;
			align-items: flex-start;

			button[data-testid='symbol-search-button'] {
				text-align: left;
			}

			input.textbox {
				min-width: unset;
			}
		}

		.transactions-container {
			overflow-y: unset;
			max-height: unset;
			box-sizing: border-box;

			.portfolio-table-header {
				padding: 7px 0px;
			}

			div.single-row {
				padding: 12px 0px;
			}

			.common-row:first-child {
				padding: 6px 0px;

				> * {
					flex: 1;
					text-align: left;
				}

				> *:first-child {
					flex: 2;
				}

				> *:last-child {
					text-align: right;

					button {
						padding-right: 10px;
					}
				}
			}

			.common-row {
				padding: 5px 0px;
				border-top: 1px solid var(--md-color-divider-gray);
				gap: 5px;

				> *:not(:first-child) {
					text-align: unset;
					flex: 1;
				}

				> *:first-child {
					text-align: unset;
					flex: 1.2;
				}

				input[type='date'] {
					background: unset;
					width: 100%;
				}

				input[type='date']::-webkit-date-and-time-value {
					text-align: right;
				}

				input[name='fees'] {
					padding-right: 5px;
				}

				.labelLink {
					width: unset;
				}
			}

			.single-porfolio {
				display: flex;
				flex-direction: column;
				box-sizing: border-box;
				padding-bottom: 23px;
				border-bottom: 1px solid var(--md-color-dark-gray);
			}
		}
	}
}
