.collapsible-container {
    details {
        border: 1px solid var(--md-color-blur-white);
        padding: 15px 15px;
        box-sizing: border-box;
        background: var(--md-color-white);

        summary {
            list-style: none;
            display: flex;
            align-items: center;
            cursor: pointer;
            transition: margin 100ms ease-out;
            user-select: none;

            h4 {
                line-height: 24px;
                margin-left: 10px;
            }

            ::before {
                display: inline-block;
                content: '';
                font-size: 25px;
            }

            svg[data-icon="minus"] {
                display: none;
            }

            &::-webkit-details-marker {
                display: none;
            }
        }


        &[open] {
            summary::before {
                content: '';
                font-size: 25px;
            }

            svg[data-icon="plus"] {
                display: none;
            }

            svg[data-icon="minus"] {
                display: inline-block;
            }

            summary {
                margin-bottom: 10px;
            }
        }

        .content-container {
            padding: 0px 0px 0px 0px;
        }
    }
}