.symbol-search-results-container {
    table {
        th {
            padding: 10px 0;
            border-bottom: 1px solid var(--md-color-blur-white);

            &:first-of-type {
                width: 80%;
            }

            &:nth-child(2) {
                width: 20%;
            }
        }

        tr {
            line-height: 14px;
            font-size: 12px;

            a {
                text-decoration: none;

                &.labelLink-m {
                    font-size: 12px;
                    font-family: var(--md-font-family-helvetica-neue-regular);
                }
            }

            td {
                color: var(--md-color-dark-gray);
                font-family: var(--md-font-family-helvetica-neue-regular);
                font-size: 12px;
                letter-spacing: 0;
                line-height: 12px;
                padding: 4px 0;
            }
        }
    }
}