.page-404-container {
  h1 {
    font-size: 25px;
    color: var(--md-color-dark-blue);
    box-sizing: content-box;
    margin: 0px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: inherit;
  height: 80vh;
}