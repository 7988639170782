.news-container {
	.component-header {
		margin-bottom: 10px;
	}

	.period-dropdown {
		margin-bottom: 21px;
	}

	.calendar-section {
		box-sizing: border-box;
		display: flex;
		width: 100%;
		align-items: end;
		justify-content: space-between;
		margin-bottom: 15px;

		.calendar {
			display: flex;
			width: 72%;

			:first-child {
				margin-right: 8px;
			}
		}

		.calendar-item {
			display: flex;
			flex-direction: column;
			width: 48%;
		}

		.title {
			font-size: var(--md-font-size-small);
			font-family: var(--md-font-family-helvetica-neue-medium);
		}

		.button {
			width: 25%;
			display: flex;
			justify-content: center;
		}
	}

	input[type='date'] {
		background: unset;
		text-align: left;
	}

	input[type='date']::-webkit-calendar-picker-indicator {
		width: 18px;
		height: 18px;
		margin-right: -8px;
		cursor: pointer;
	}

	input[type='date']::-webkit-date-and-time-value {
		text-align: right;
	}

	.company-news {
		max-height: 245px;
		overflow-y: auto;
		// min-height: 120px;

		.no-data {
			margin: 20px 0;
			min-height: 120px;
			height: auto;
		}
	}
}

@media screen and (min-width: 0px) and (max-width: 1024px) {
	.news-container {
		.calendar-section {
			display: flex;
			flex-direction: column;
			margin-bottom: 15px;

			.calendar {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				margin-bottom: 15px;
				width: 100%;
			}

			.calendar-item {
				width: 45%;
			}

			.button {
				width: 70%;
				margin: 0 auto 15px;
			}
		}
	}
}
