.quotes-news {
	font-family: var(--md-font-family-helvetica-neue-bold);
	font-size: 16px;
	line-height: 19px;
	padding: 0px;
	color: var(--md-color-black);

	.tabs-container {
		display: flex;
		width: 100%;
		justify-content: space-between;

		.left-side-header {
			.tab-headers {
				display: flex;

				.searchbox {
					padding-top: 15px;
					padding-left: 15px;

					.searchbox-container {
						.textbox-container {
							input {
								min-width: 180px;
							}
						}
					}
				}
			}
		}

		.right-side-header {
			font-size: 14px;
			line-height: 19px;

			.tab-button {
				height: 40px;
				width: 90px;
				background: transparent !important;
				color: var(--md-color-black) !important;
				margin-top: 15px;
				font-family: var(--md-font-family-palatino-regular);
				font-size: 14px;
				line-height: 19px;

				span {
					position: absolute;
					padding-left: 5px;
					margin-top: 2px;
				}

				&:hover {
					background: transparent !important;
					color: var(--md-color-black) !important;
				}
			}
		}
	}

	.quotes-news-body {
		padding: 5px 0px 0px 0px;
		display: flex;
		gap: 20px;

		&.no-watchlist-view {
			.left-container {
				width: 100%;
			}

			.right-container {
				width: 0;
			}
		}

		.left-container {
			width: 67%;
			overflow: hidden;

			.markets-container {
				display: flex;
				justify-content: space-between;
				padding: 0 0px 25px 0px;
				width: 100%;
				gap: 20px;
				box-sizing: border-box;
				flex-direction: column;

				.markets-todays-performers {
					width: 100%;

					.performersContainer {
						height: 335px !important;

						.headerMain {
							padding-top: 0px !important;
							padding-bottom: 0px !important;
						}

						.performersContentMain {
							.tableContent {
								max-height: 250px !important;
							}

							.commonCol {
								padding: 10px !important;

								&:first-child {
									padding: 10px 10px 10px 0px !important;
								}
							}
						}
					}
				}

				.markets-statistics {
					table {
						margin-bottom: 0px !important;

						tr {

							th,
							td {
								padding: 10px !important;

								&:first-child {
									padding: 10px 10px 10px 0px !important;
								}

								&:nth-last-child(-n + 6) {
									border-bottom: 0px !important;
								}
							}
						}
					}
				}
			}
		}

		.right-container {
			width: 33%;
		}
	}
}

@media screen and (min-width: 596px) and (max-width: 1024px) {
	.quotes-news {
		&.watchlist-overlay-on {
			height: 90vh;
			overflow: hidden;

			.watchlist-overlay {
				background-color: rgba(0, 0, 0, 0.4);
				pointer-events: none;
				transition: opacity 0.3s ease-in-out;
				width: 100%;
				position: fixed;
				height: 100vh;
			}

			.tabs-container {
				pointer-events: none;

				.tab-headers ul>li {
					padding-right: 22px;
				}
			}

			.dropDownMain .basicDropDownContainer {
				position: initial;
			}

			.container {
				pointer-events: none;
			}

			.chart-container {
				div[id^='highcharts-'] {
					position: static !important;
				}
			}

			#quote-new-header-tab {
				z-index: 0;
			}

			div[data-highcharts-chart] {
				div:nth-child(2) {
					position: inherit !important;
				}
			}

			.chart-main-container {
				.chart-container {
					#indicesVolumDropdown {
						.selected-indicator {
							background-color: unset !important;
						}
					}
				}
			}
		}

		.tabs-container {
			.left-side-header {
				width: 90%;

				.tab-headers {
					display: flex;
					flex-direction: row;
					justify-content: space-between;

					.searchbox {
						.searchbox-container {
							.textbox-container {
								input {
									min-width: 185px;
								}
							}

							button {
								margin-left: 0px;
							}
						}

						.searchbox-container span {
							min-width: 50px;
						}
					}
				}
			}

			.right-side-header {
				width: 7%;
			}
		}

		.quotes-news-body.ipad-view {
			&.no-watchlist-view {
				.container {
					width: 100%;
				}
			}

			.container {
				width: 90.5%;

				.markets-container {
					display: flex;
					flex-direction: column;
					gap: 20px;

					.markets-todays-performers {
						width: 100%;

						.performersContainer {
							height: 330px !important;

							.headerMain {
								padding-top: 0px !important;
								padding-bottom: 0px !important;
							}

							.performersContentMain {
								.tableContent {
									max-height: 250px !important;
								}

								.commonCol {
									padding: 10px !important;

									&:first-child {
										padding: 10px 10px 10px 0px !important;
									}
								}
							}
						}
					}

					.markets-statistics {
						table {

							th,
							td {
								padding: 10px !important;

								&:first-child {
									padding: 10px 10px 10px 0px !important;
								}

								&:nth-last-child(-n + 6) {
									border-bottom: 0px !important;
									// padding: 10px 10px 0px 0px !important;
								}
							}
						}
					}
				}
			}
		}

		.help-Refresh {
			float: right;
			padding-right: 67px;

			.tab-button {
				height: 40px;
				width: 90px;
				background: transparent !important;
				color: var(--md-color-black) !important;
				margin-top: 15px;
				font-family: var(--md-font-family-palatino-regular);
				font-size: 14px;
				line-height: 19px;

				span {
					position: absolute;
					padding-left: 5px;
					margin-top: 2px;
				}

				&:hover {
					background: transparent !important;
					color: var(--md-color-black) !important;
				}
			}
		}
	}
}

@media screen and (min-width: 0px) and (max-width: 595px) {
	.quotes-news {
		font-family: var(--md-font-family-helvetica-neue-bold);
		font-size: 16px;
		line-height: 19px;
		padding: 0px;
		color: var(--md-color-black);

		.searchbox {
			padding-top: 13px;
			padding-bottom: 15px;

			.searchbox-container {
				.textbox-container {
					input {
						min-width: 180px;
					}
				}
			}
		}

		.help-Refresh {
			float: right;
			padding-right: 22px;

			.tab-button {
				height: 40px;
				width: 90px;
				background: transparent !important;
				color: var(--md-color-black) !important;
				margin-top: 15px;
				font-family: var(--md-font-family-palatino-regular);
				font-size: 14px;
				line-height: 19px;

				span {
					position: absolute;
					padding-left: 5px;
					margin-top: 2px;
				}

				&:hover {
					background: transparent !important;
					color: var(--md-color-black) !important;
				}
			}
		}

		.quotes-news-customize-Watchlist {
			div[data-watchlist-name='customize-watchlist-content-data'] {
				width: 100%;
			}
		}

		.quotes-glossary {
			div[id='glossary-container'] {
				position: relative !important;
			}
		}

		.tabs-container {
			display: flex;
			width: 100%;
			justify-content: space-between;

			.tab-headers ul>li {
				padding: 0 10px;
				border-right: 1px solid var(--md-color-blur-white);
				display: flex;
				align-items: center;
				justify-content: center;
				width: auto;
				text-align: center;


				span {
					border-right: none !important;
				}

				&:last-child {
					border-right: none !important;
				}
			}

			.left-side-header {
				.tab-headers {
					display: flex;
				}
			}

			.right-side-header {
				font-size: 14px;
				line-height: 19px;
			}
		}

		.quotes-news-body {
			padding: 5px 0px 62px 0px;
			display: inherit;
			gap: 0px;

			&.no-watchlist-view {
				.left-container {
					width: 100%;
				}

				.right-container {
					width: 0;
				}
			}

			.markets-container {
				display: flex;
				justify-content: space-between;
				padding: 0 0px 25px 0px;
				width: 100%;
				gap: 20px;
				box-sizing: border-box;
				flex-direction: column;

				.markets-todays-performers {
					border: 0.5px solid var(--md-color-blur-white);
					width: 100%;
				}

				#todays-performers-container {
					.performersContainer {
						height: 275px !important;

						.performersContentMain {
							padding-bottom: 1rem;

							.headerMain {
								padding-top: 0px !important;
								padding-bottom: 0px !important;
							}

							.tableContent {
								max-height: 165px !important;
							}

							.commonCol {
								padding: 10px !important;

								&:first-child {
									padding: 10px 10px 10px 0px !important;
								}
							}
						}
					}
				}

				#statistics-container {
					table {
						margin-bottom: 0px !important;

						td {
							&:first-child {
								padding: 10px 10px 10px 0px !important;
							}

							&:nth-last-child(-n + 2) {
								border-bottom: 0px !important;
							}
						}
					}
				}
			}

			.right-container {
				width: 33%;
			}
		}
	}
}