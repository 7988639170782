.modcharts_container {
	width: 98%;
	position: relative;

	div[id='chart-dom-element'] {
		min-height: 510px;
		width: 99%;
		overflow-x: hidden;
		overflow-y: hidden;

		.modcharts-panel {
			.modcharts-legend {
				z-index: 1;
			}
		}
	}

	.loading-wrapper {
		position: absolute;
		width: 100%;
	}
}

.chartEvent {
	font-size: 14px;
	line-height: 18px;
	color: var(--md-color-white);
	text-align: center;
	cursor: pointer;
	padding-top: 1px;
	border-radius: 2px;
	width: 23px;
	height: 23px;
	position: absolute;
}

.dividends {
	background-color: var(--md-color-red-brown);
}

.splits {
	background-color: var(--md-color-light-green);
}

.news {
	background-color: var(--md-color-sea-green);
}

.modcharts-rootmessages {
	font-family: var(--md-font-family-helvetica-neue-regular);
	position: absolute;
	color: #666;
	font-size: 15px;
	z-index: 1;
	padding: 0;
	width: 100%;
	height: 100%;
}

.modcharts-rootmessages .modcharts-chartnotavailable {
	padding-top: 180px;
	margin: auto;
	width: 270px;
	height: 70px;
	text-align: center;
}

.modcharts-noselect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.modcharts-rootoverlay line {
	z-index: 2;
}

div[id='priceChart'] {
	div[class='modcharts-panel'] {
		z-index: 1;

		div[class='modcharts-legend'] {
			z-index: 1;
			margin-bottom: 10px;
		}
	}

	div[class='modcharts-panel']:nth-child(2) {
		z-index: 0 !important;
	}
}

div[class='panelLegend'] {
	background: transparent;
	border-radius: 3px;
	padding: 3px 10px 6px 1px;
	line-height: 18px;
	margin-top: -30px;
	width: 110px;
	overflow: hidden;
	font-family: var(--md-font-family-helvetica-neue-regular);
	cursor: pointer;

	button[class='closeButton'] {
		content: '\00d7';
		cursor: pointer;
		font-size: 20px;
		top: 12px;
		right: 10px;
		border: none;
		background-color: transparent;
	}

	button[class='closeButton']::after {
		content: '\00d7';
		cursor: pointer;
		font-size: 20px;
		top: 12px;
		right: 10px;
		border: none;
		background-color: transparent;
	}
}

.selected-indicator,
.indicesVolumDropdownList {
	text-indent: 1px;
	text-overflow: '';
	width: 110px;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	padding: 2px;
	border: none;
	font-size: 12px;
	color: var(--md-color-black);
	line-height: 18px;
	padding-right: 8px;
	cursor: pointer;
}

.selected-indicator {
	background: #fff
		url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="%232B6D9F" d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"/></svg>')
		right 1rem center/15px 18px no-repeat;
	float: left;
	font-family: var(--md-font-family-helvetica-neue-medium);
}

.indicesVolumDropdownList {
	list-style: none;
	display: none;
	background: var(--md-color-white);
	padding: 0;
	margin-top: 22px;
	box-shadow: -1px 0 6px 2px rgba(0, 0, 0, 0.1),
		1px 4px 6px 2px rgba(0, 0, 0, 0.1);
	font-family: var(--md-font-family-helvetica-neue-regular);
}

.indicesVolumDropdownList > li:hover {
	background-color: var(--md-color-chart-border);
	color: white;
}

.indicesVolumDropdownList > li {
	padding: 12px 18px 12px 6px;
	border-top: 1px solid var(--md-color-divider-gray);
}

/* Chart loading */
div[class='modcharts-root modcharts-noselect modcharts-cursor-default modcharts-root-loading'] {
	pointer-events: none;

	.modcharts-panel,
	.modcharts-legend {
		opacity: 0.5;
	}
}

@keyframes pulse {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0;
	}
}

div[class='modcharts-root modcharts-noselect modcharts-cursor-default modcharts-root-loading']::before {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	animation: pulse 2s ease infinite;
	content: '';
	font-size: 1rem;
	opacity: 1;
}

.modcharts-root-loading .modcharts-legend,
.modcharts-root-loading .modcharts-panel {
	opacity: 0.5;
}

/* Cursor */
.modcharts-cursor-default {
	cursor: default;
}

.modcharts-cursor-pointer {
	cursor: pointer;
}

.modcharts-cursor-ns-resize {
	cursor: ns-resize;
}

.modcharts-cursor-cross {
	cursor: crosshair;
}

.modcharts-cursor-closed_hand {
	cursor: grabbing;
}

/* Crosshair and circle */
.modcharts-crosshair {
	position: absolute;
	color: var(--md-color-light-blur-gray);
	height: 20px;
}

.modcharts-crosshair-horiz,
.modcharts-crosshair-vert {
	stroke: #777;
	stroke-width: 1;
	stroke-dasharray: 2, 1;
	shape-rendering: crispEdges;
}

.modcharts-crosshair-circle {
	fill: var(--md-color-white);
	stroke: #333;
	width: 3.5px;
	stroke-width: 1;
}

.modcharts-flag-xaxis {
	position: absolute;
	padding: 0 0.4rem;
	border-radius: 2px;
}

div[class*='modcharts-cursor-cross'] {
	cursor: crosshair;
}

[class*='modcharts-crosshair'] {
	position: absolute;
	color: var(--md-color-light-blur-gray);
	height: 20px;
}

g[class*='modcharts-crosshair-horiz'],
[class*='modcharts-crosshair-vert'] {
	stroke: #777;
	stroke-width: 1;
	stroke-dasharray: 2, 1;
	shape-rendering: crispEdges;
}

circle[class*='modcharts-crosshair-circle'] {
	fill: var(--md-color-white);
	stroke: #333;
	width: 3.5px;
	stroke-width: 1;
}
