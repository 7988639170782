.symbol-container {
    width: 100%;
    height: 100%;
    font-family: var(--md-font-family-helvetica-neue-regular);
    color: var(--md-color-black);
    font-size: 12px;
    line-height: 14px;

    .company-details {
        padding: 10px 5px 5px 10px;

        .heading {
            text-align: left;
            font-size: 14px;
            line-height: 19px;
            white-space: nowrap;
        }

        .company-key {
            display: flex;
            justify-content: space-between;
            color: var(--md-color-dark-gray);

            .company-value-text {
                text-align: center;
                width: 28%;
            }

            .company-value-text:first-child {
                text-align: left;
            }

            .company-value-text:nth-child(2) {
                width: 44%;
            }
        }

        .company-footer {
            padding-top: 10px;
            color: var(--md-color-dark-gray);

            span {
                color: inherit;
            }
        }

        .company-value {
            display: flex;
            justify-content: space-between;
            padding-top: 5px;

            .company-value-text {
                text-align: center;
                width: 28%;
            }

            .company-value-text:first-child {
                text-align: left;
            }

            .company-value-text:nth-child(2) {
                font-family: var(--md-font-family-helvetica-neue-bold);
                font-size: 12px;
                line-height: 15px;
                width: 44%;
            }
        }
    }
}