.symbol-details-container {
  .symbol-details-label {
    display: inline-block;
  }

  #popover {
    min-width: 265px;
    height: 92px;
    z-index: 999;
    left: 10;
    font-size: 12px;
    color: var(--md-color-black);
    line-height: 16px;
    padding: 0px 0px 5px 2px;
    background: var(--md-color-light-white-background);
    box-shadow: -1px 2px 6px 2px rgba(0, 0, 0, 0.10), 1px 2px 6px 2px rgba(0, 0, 0, 0.10);
    pointer-events: none;

    span {
      white-space: normal;
    }
  }
}