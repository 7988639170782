.options-symbology {
    font-family: var(--md-font-family-helvetica-neue-regular);

    .static-content {
        font-size: 12px;
        margin-bottom: 0 !important;
        color: var(--md-color-black) !important;

        span {
            font-family: var(--md-font-family-helvetica-neue-bold);
        }
    }

    .options-symbol {
        .options-symbol-header {
            margin-bottom: 10px;

            .options-symbol-title {
                font-family: var(--md-font-family-helvetica-neue-bold);
            }
        }

    }

    .options-symbol-table-container {
        margin: 0 auto;
        max-width: 400px;


        .options-symbol-table {
            margin-top: 10px;

            th span {
                font-family: var(--md-font-family-helvetica-neue-regular) !important;
            }

            td:first-of-type {
                font-family: var(--md-font-family-helvetica-neue-bold);
            }
        }

        .decimal-point-note {
            margin-top: 10px;
        }
    }

    .oic-note {
        margin-top: 20px;

        a {
            color: var(--md-color-blue);
        }
    }

    td {
        span {
            font-family: var(--md-font-family-helvetica-neue-bold);
        }
    }
}