.checkbox-wrapper {
    position: relative;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    user-select: none;

    input[type='checkbox'] {
        opacity: 0;
        position: absolute;
        width: 0;
        height: 0;

        +label {
            border: 2px solid var(--md-color-dark-gray);
            height: 20px;
            width: 20px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-right: 0;
            cursor: pointer;

            &::before {
                content: '✔';
                display: block;
                color: var(--md-color-blue);
                font-size: 16px;
                opacity: 0;
                transition: opacity 0.2s ease-in-out;
            }
        }

        &:checked+label {
            border: 2px solid var(--md-color-blue);

            &::before {
                opacity: 1;
            }
        }

        &:disabled+label {
            cursor: not-allowed;
        }
    }
}