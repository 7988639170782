.overlay-container {

    .overlay-period {
        font-family: var(--md-font-family-helvetica-neue-medium);

        .textbox-container {
            margin-left: 15px;
            width: 80px;
            height: 36px;
        }
    }

    .overlay-description {
        font-family: var(--md-font-family-helvetica-neue-regular);
        font-size: 12px;
        line-height: 18px;
        color: var(--md-color-dark-gray);
        padding: 20px 0px;
    }

    .overlay-handlers {
        display: flex;
        justify-content: center;

        button {
            width: 35%;
            margin-right: 20px;
            &.secondary {
                color: var(--md-color-dark-gray);
            }
        }
    }
}

@media screen and (min-width: 0px) and (max-width: 595px) {

    .overlay-container {
        border-top: 1px solid var(--md-color-divider-gray);

        .overlay-period {
            padding-top: 20px;
        }

        .overlay-description {
            padding: 20px 0px 25px 0px;
            border-bottom: 1px solid var(--md-color-divider-gray);
        }

        .overlay-handlers {
            display: flex;
            justify-content: center;
            padding-top: 20px;

            button {
                width: 45%;
            }
        }
    }
}