.textbox-container {
  display: inline-block;

  label {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .textbox {
    font-family: var(--md-font-family-helvetica-neue-regular);
    font-size: var(--md-font-size-small);
    padding: 7px 18px 7px 12px;
    border: 1px solid var(--md-color-blur-white);
    height: 40px;
    box-sizing: border-box;
    width: 100%;

    &:focus {
      outline: none;
      border-radius: 0px;
      border: 1px solid var(--md-color-blue);
    }
  }

  .textbox::placeholder {
    color: var(--md-color-dark-gray);
  }

  .textbox-s {
    padding: 2px 18px 2px 12px;
    height: 36px;
  }
}