.button {
  display: inline-block;
  padding: 11px 32px;
  background-color: var(--md-color-blue);
  color: var(--md-color-white);
  border: none;
  cursor: pointer;
  height: 39px;
  font-size: 12px;
  font-family: var(--md-font-family-helvetica-neue-regular);

  // TODO: color to be changed when provided
  &.disabled {
    background-color: grey;
  }

  &.primary {
    background-color: var(--md-color-blue);
    color: #fff;

    // TODO: color to be changed when provided
    &:hover {
      background-color: #005fa1;
    }
  }

  &.secondary {
    // TODO: color to be changed when provided
    background-color: #fff;
    color: var(--md-color-blue);
    border: 1px solid var(--md-color-blue);

    &:hover {
      // TODO: color to be changed when provided
      background-color: #dedede;
      color: #005fa1;
    }
  }

  &:disabled {
    // TODO: color to be changed when provided
    background-color: #b4b4b4;
    color: #ffffff;
    cursor: not-allowed !important;

    &:hover {
      background-color: var(--md-color-dark-gray);
    }
  }

  .button-icon {
    padding: 0 10px;
  }
}

.button-s {
  height: 36px;
  min-width: 50px;
}