.option-chain-table-wrapper {
    &:not(:first-of-type) {
        .common-row.header {
            display: none;
        }
    }

    .calls-puts-row {
        padding: 10px 0 10px 5px;
        font-weight: 500;
        line-height: 17px;
    }

    .option-chain-table-content {
        &.in-money {
            background: var(--md-color-light-white-background);
        }

        .common-row {
            .labelLink {
                color: var(--md-color-blue);
                font-family: var(--md-font-family-helvetica-neue-regular);
                line-height: 14px;
            }

            .click-icon {
                margin: 0px;
                border: none;
                background: none;
                cursor: pointer;
            }
        }
    }
}

@media screen and (max-width: 596px) {

    .option-chain-table-wrapper {
        .common-row {
            >div,
            .strike-cell {
                width: 30%;
                text-align: center;

                &:last-child {
                    text-align: right;
                    padding-right: 10px;
                }
            }
        }
    }
}