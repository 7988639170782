.view-watchlists-container {
	.has-loader {
		min-height: 700px;
	}

	#news-article-modal {
		z-index: 999999;
	}

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;

		.watchlist-left {
			display: flex;
			justify-content: space-around;
		}

		.watchlist-header {
			color: var(--md-color-black);
			font-family: var(--md-font-family-palatino-regular);
			font-size: var(--md-font-size-extra-large);
			letter-spacing: 0;
			line-height: 32px;
		}

		.customize-watchlist-link {
			a {
				color: var(--md-color-blue);
				font-family: var(--md-font-family-helvetica-regular);
				font-size: var(--md-font-size-small);
				font-weight: bold;
				letter-spacing: 0;
				cursor: pointer;
				line-height: 28px;
				text-decoration: underline;
				margin-left: 10px;
			}
		}
	}

	.display-type-container {
		display: flex;
		justify-content: space-between;
		font-size: var(--md-font-size-small);
		font-family: var(--md-font-family-helvetica-neue-regular);
		color: var(--md-color-light-grey);
		text-decoration: none;

		> a {
			line-height: var(--md-font-size-regular);
			cursor: pointer;
		}

		.labelLink-active {
			text-decoration: underline;
		}

		.seperator {
			margin: 0 10px;
			font-size: var(--md-font-size-small);
		}
	}

	.message-box {
		margin: 20px;
	}

	.table-container {
		table {
			margin: 0;
			touch-action: none;

			tr {
				td {
					padding: 0;
					line-height: 15px;

					#news-article-modal {
						background-color: rgba(0, 0, 0, 0.08);

						.modal-container {
							box-shadow: 0 0 20px rgba(0, 0, 0, 0.04);
						}
					}

					.separator {
						margin: 13px 0;

						color: var(--md-color-dark-gray);

						&:last-child {
							display: none;
						}
					}

					.performance-indicator-bar {
						height: 45px;
						width: 6px;
						margin-right: 10px;

						&.green {
							background-color: var(--md-color-green);
						}

						&.red {
							background: var(--md-color-red);
						}

						&.black {
							background: var(--md-color-black);
						}
					}

					.symbol-details-label a {
						color: var(--md-color-black);
						font-size: var(--md-font-size-small);
						font-family: var(--md-font-family-helvetica-neue-bold);
						font-weight: bold;
						letter-spacing: 0.25px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						max-width: 100px;
						line-height: initial;
					}

					.symbol {
						color: var(--md-color-dark-gray);
						font-size: var(--md-font-size-small);
						letter-spacing: 0.25px;
					}

					.last-change-row-wrapper {
						display: flex;
						position: relative;

						.cell-wrapper {
							flex-direction: column;
							display: flex;
							flex: 1;
							gap: 10px;
							font-family: var(--md-font-family-helvetica-regular);

							&:nth-child(3) {
								max-width: 20%;
							}

							&.name-wrapper {
								max-width: 30%;
							}

							&.change-icon {
								flex: 0.2;
								position: absolute;
								right: 4px;
								top: 0;
							}

							.last,
							.change {
								color: var(--md-color-dark-gray);
								font-size: 11px;
								letter-spacing: 0.23px;
								text-align: center;
							}

							.change {
								svg {
									vertical-align: middle;
								}
							}

							.last-value {
								text-align: center;

								.label {
									color: var(--md-color-black);
									font-size: var(--md-font-size-regular);
									font-weight: bold;
									letter-spacing: 0.29px;
									font-family: var(--md-font-family-helvetica-neue-bold);
								}
							}

							.change-wrapper {
								flex-direction: row;
								font-size: var(--md-font-size-regular);
								font-weight: 500;
								letter-spacing: 0.29px;
								display: flex;
								align-items: center;
								justify-content: center;

								.divider {
									font-size: var(--md-font-size-small);
									margin: 0 10px;
									width: 2px;
								}

								.label {
									font-size: var(--md-font-size-regular);
									font-family: var(--md-font-family-helvetica-neue-medium);
								}
							}

							.news-headline {
								font-family: var(--md-font-family-helvetica-regular);
								color: var(--md-color-dark-gray);
								font-size: var(--md-font-size-small);
								letter-spacing: 0.25px;
								line-height: 14px;
							}
						}
					}

					.news-row-wrapper {
						display: flex;

						.cell-wrapper {
							display: flex;
							justify-content: space-between;
							width: 100%;

							.symbol-headline-wrapper {
								display: flex;
								flex-direction: column;
								gap: 5px;
								width: 85%;
								justify-content: center;

								.symbol-info {
									gap: 10px;
									display: flex;
								}

								svg {
									height: 50px;
								}
							}

							.news-headline {
								font-family: var(--md-font-family-helvetica-regular);
								color: var(--md-color-dark-gray);
								font-size: var(--md-font-size-small);
								letter-spacing: 0.25px;
								line-height: 14px;
								overflow: hidden;
								text-overflow: ellipsis;
								display: -webkit-box;
								-webkit-line-clamp: 2;
								-webkit-box-orient: vertical;

								&:not(.no-news):hover {
									text-decoration: underline;
									cursor: pointer;
								}
							}
						}
					}
				}

				&:last-child {
					td {
						.separator {
							display: block;
						}
					}
				}
			}
		}

		.pagination-container {
			.pagination-info {
				line-height: 12px;
				padding-top: 3px;
			}
		}
	}
}

@media screen and (min-width: 0px) and (max-width: 595px) {
	.view-watchlists-container {
		padding: initial;
		border: none;

		.table-container {
			table {
				height: 64vh;
				display: block;
				margin-bottom: 5px;

				tbody {
					display: block;
					overflow: scroll;
					max-height: 64vh;

					tr {
						width: 100%;
						display: inline-table;

						td {
							.last-change-row-wrapper {
								.cell-wrapper {
									.last-value {
										.label {
											font-size: var(--md-font-size-small);
										}
									}
								}
							}

							.cell-wrapper {
								&.change-icon {
									top: 5px;
								}
							}
						}
					}
				}
			}
		}

		.mobile-view {
			position: fixed !important;
			bottom: 0 !important;
			left: 0;
			width: 100%;
			background-color: var(--md-color-white);
			padding: 10px;
			box-shadow: 0 -1px 0 0 var(--md-color-blur-white);
			z-index: 999;
			border-top: 1px solid var(--md-color-black);

			&.open {
				transform: translateY(-100%);
				transition: transform 0.3s ease-out;
				position: fixed;
				top: 71px;
				z-index: 2;

				.watchlist-bottom-drawer {
					display: none;
				}
			}

			svg {
				padding-top: 2px;
			}

			.watchlist-bottom-drawer {
				display: flex;
				justify-content: center;
				margin: 5px 0px;

				.row {
					display: flex;
					gap: 10px;

					.header {
						color: var(--md-color-black);
						font-family: var(--md-font-family-palatino-regular);
						font-size: var(--md-font-size-large);
						letter-spacing: 0;
						line-height: 32px;
						padding: 0;
					}
				}
			}

			.watchlist-icon {
				background-color: #f1f1f1;
				border: none;
				padding: 10px 20px;
				cursor: pointer;
			}

			.watchlist-drawer {
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				background-color: #ffffff;
				padding-right: 20px;
				display: flex;
				flex-direction: column;
				transition: transform 0.3s ease-in-out;
				transform: translateY(100%);
				height: 100svh; // safari fix
				overflow: hidden;

				.content {
					padding: 10px;

					.header {
						display: flex;
						align-items: center;
						justify-content: space-between;

						&-left {
							display: flex;
							gap: 10px;
						}

						&-icon {
							width: 10px;
							height: 10px;
							margin-right: 10px;
						}

						&-name {
							color: var(--md-color-black);
							font-family: var(--md-font-family-palatino-regular);
							font-size: var(--md-font-size-large);
							letter-spacing: 0;
							line-height: 32px;
						}

						&-right {
							display: flex;
							align-items: center;
							gap: 50px;

							.customize-watchlist-link {
								font-family: var(--md-font-family-helvetica-neue-bold);
								margin-top: -5px;
								text-decoration: underline;
							}
						}

						&-link {
							margin-right: 10px;

							a {
								text-decoration: none;
								color: #000;
							}
						}

						&-close-button {
							padding: 5px 10px;
							border: none;
							cursor: pointer;
						}
					}
				}
			}
		}

		.display-type-container {
			width: 50%;
			justify-content: center;
			align-items: center;
			margin: 0 auto 6px;

			.seperator {
				margin: 0 20px;
				font-size: var(--md-font-size-small);
			}
		}
	}
}

@media screen and (min-width: 0px) and (max-width: 595px) and (min-height: 701px) and (max-height: 1000px) {
	.view-watchlists-container {
		.table-container {
			table {
				height: 68vh;

				tbody {
					max-height: 68vh;
				}
			}
		}
	}
}

@media screen and (min-width: 596px) and (max-width: 1024px) {
	.view-watchlists-container {
		padding: initial;
		border: none;

		.ipad-view {
			display: flex;
			position: fixed;
			bottom: 0;
			right: 0;
			width: 50px;
			height: 100vh;
			background-color: var(--md-color-white);
			padding: 10px;
			box-shadow: 0 -1px 0 0 var(--md-color-blur-white);
			opacity: 1;
			justify-content: center;
			border-left: 1px solid var(--md-color-black);

			&.open {
				transform: translateY(-100%);
				transition: width 0.3s ease-out;
				position: fixed;
				top: 0px;
				z-index: 99999;

				.watchlist-bottom-drawer {
					display: none;
				}
			}

			svg {
				padding-top: 2px;
			}

			.watchlist-bottom-drawer {
				display: flex;
				justify-content: center;
				margin-bottom: 10px;
				align-items: center;

				.row {
					display: flex;
					gap: 10px;

					.header {
						display: none;
					}
				}
			}

			.watchlist-icon {
				background-color: #f1f1f1;
				border: none;
				padding: 10px 20px;
				cursor: pointer;
			}

			.watchlist-drawer {
				position: absolute;
				top: 90px;
				right: 0;
				background-color: #ffffff;
				display: flex;
				transition: transform 0.3s ease-in-out;
				transform: translateY(100%);
				height: 100vh;
				overflow: hidden;
				flex-direction: row;
				justify-content: space-between;
				z-index: 999999;

				.header {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding: 10px 20px 0 10px;

					&-left {
						display: flex;
						gap: 10px;
					}

					&-icon {
						margin-right: 10px;
						display: flex;
						align-items: center;
						justify-content: center;
						height: 100vh;
						width: 65px;
						border-right: 1px solid var(--md-color-blur-white);
					}

					&-name {
						color: var(--md-color-black);
						font-family: var(--md-font-family-palatino-regular);
						font-size: var(--md-font-size-large);
						letter-spacing: 0;
						line-height: 32px;
					}

					&-right {
						display: flex;
						align-items: center;
						gap: 50px;

						.customize-watchlist-link {
							font-family: var(--md-font-family-helvetica-neue-bold);
							margin-top: -5px;
							margin-left: 20px;
							text-decoration: underline;
						}
					}

					&-link {
						margin-right: 10px;

						a {
							text-decoration: none;
							color: #000;
						}
					}

					&-close-button {
						padding: 5px 10px;
						border: none;
						cursor: pointer;
					}
				}

				.content {
					min-width: 350px;
					width: 100%;

					.table-container {
						width: 40vh;
					}
				}
			}
		}

		.display-type-container {
			width: 50%;
			justify-content: center;
			align-items: center;
			margin: 0 auto;

			.seperator {
				margin: 0 20px;
				font-size: var(--md-font-size-small);
			}
		}
	}
}
