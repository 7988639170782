.rc-component-container {
    width: 100% !important;
    max-width: -moz-available;
    max-width: -webkit-fill-available;

    &.has-padding {
        padding: 10px;
    }

    &.has-border {
        border: 1px solid var(--md-color-blur-white);
    }

    &.has-white-bg {
        background: var(--md-color-white);
    }

    &.has-loader {
        min-height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}