.tab-container {
	.loader-container {
		margin: 20px 0;
	}

	.common-row.portfolio-table-header {
		align-items: end;
		border: none;

		span {
			height: auto;
		}
	}

	.expand-icon {
		cursor: pointer;
	}

	.notes-icon {
		cursor: pointer;

		svg {
			vertical-align: bottom;
		}
	}

	.fundamentals_tab,
	.performance_tab,
	.quotes_tab {
		.common-row {
			display: flex;
			justify-content: space-between;
			padding: 10px 0px;
			border-top: 1px solid var(--md-color-divider-gray);
			gap: 10px;

			&.portfolio-table-header:first-child {
				span {
					font-family: var(--md-font-family-helvetica-neue-regular);
				}
			}

			span {
				font-size: 12px;
				font-family: var(--md-font-family-helvetica-neue-bold);

				&.expandable-symbol {
					display: flex;
					gap: 10px;
					width: 90%;

					.company-name-column {
						.symbol-details-container {
							.symbol-details-label {
								>a {
									max-width: 95px;
								}
							}
						}
					}

					.symbol-details-container {
						span {
							font-family: var(--md-font-family-helvetica-neue-regular);
						}
					}

				}

				&.greyLabel {
					color: var(--md-color-dark-gray);
				}

				&.name-without-news {
					width: 130%;
				}
			}

			&.transaction-row {
				.label {
					font-family: var(--md-font-family-helvetica-neue-regular);
				}
			}

			>*:not(:first-child) {
				text-align: right;
				width: 8%;
			}

			>*:first-child {
				text-align: left;
				width: 15%;
				display: flex;
				gap: 10px;
			}

			>*:last-child {
				width: 12%;
			}

			.labelLink {
				color: var(--md-color-blue);
				font-size: var(--md-font-size-small);
				font-family: var(--md-font-family-helvetica-neue-bold);
				line-height: 18px;
			}

			.click-icon {
				padding: 0px;
				margin: 0px;
				border: none;
				background: none;
				cursor: pointer;
			}
		}

		.last-row {
			border-top: 2px solid var(--md-color-blur-white);

			.greyLabel {
				color: var(--md-color-light-grey);
				font-family: var(--md-font-family-helvetica-neue-medium);
			}
		}

		.contentRow {
			padding-top: 8px;
			padding-right: 0px;
			margin-right: 11px;
			border-top: 1px solid var(--md-color-divider-gray);
		}

		.tableHeader:not(:first-child) {
			text-align: right;
		}

		.market-delayed-info {
			color: var(--md-color-light-grey);
			font-family: var(--md-font-family-helvetica-neue-regular);
			font-size: 11px;
			margin: 15px 0px;
			letter-spacing: 0;
			line-height: 12px;
		}
	}

	.quotes_tab {
		.common-row {

			>*:nth-child(5),
			*:nth-child(7),
			*:nth-child(8) {
				width: 12%;
			}
		}
	}

	.fundamentals_tab {
		.common-row {

			>*:nth-child(6) {
				width: 6%;
			}

			>*:last-child {
				width: 8%;
			}
		}
	}


}

@media screen and (min-width: 0) and (max-width: 1024px) {
	.tab-container {
		margin: 15px 0;

		.portfolio-wrapper {
			display: flex;
			flex-wrap: wrap;

			.holding-group:nth-last-child(2) {
				background-color: red;

				.holding-column {
					border-bottom: none;
				}
			}

			.holding-column {
				flex: 1 0 40%;
				width: 40%;
				border-bottom: 1px solid var(--md-color-dark-gray);
				margin: 0 10px;

				&.last-col {
					border-bottom: none;
				}
			}

			.holding-row,
			.transaction-row {
				display: flex;
				align-items: center;
				justify-content: space-between;

				&:first-child {
					border-top: none;
				}

				.display-label {
					width: initial;
					font-family: var(--md-font-family-helvetica-neue-regular);
					font-size: var(--md-font-size-small);
					color: var(--md-color-dark-gray);
				}

				.value {
					width: initial;

					&:first-child {
						width: 100%;
						display: flex;
						flex-direction: column;
						justify-content: space-between;

						.name-col {
							display: flex;
							justify-content: space-between;
						}
					}

					.label {
						font-family: var(--md-font-family-helvetica-neue-regular);
						line-height: 14px;
					}
				}
			}

			.transaction-group {
				border-left: 1px solid var(--md-color-dark-gray);
				padding-left: 15px;
				margin-left: 30px;
				margin-bottom: 10px;

				.transaction-column {
					border-bottom: 1px solid var(--md-color-dark-gray);

					&:last-child {
						border-bottom: none;
					}
				}
			}

			.holding-row {

				.display-label,
				.value .label {
					font-family: var(--md-font-family-helvetica-neue-bold);
				}
			}

			.combined-row {
				display: flex;
				width: 100%;
				margin-top: 10px;
				border-top: 1px solid var(--md-color-dark-gray);

				.combined-col {
					flex: 1;
					text-align: right;

					&:first-child {
						text-align: left;

						>.display-label {
							.label {
								font-family: var(--md-font-family-helvetica-neue-bold);
								color: var(--md-color-dark-gray);
								line-height: 20px;
							}
						}
					}

					>.display-label {
						.label {
							width: initial;
							color: var(--md-color-dark-gray);
						}
					}
				}

				.value {
					width: initial;

					.label {
						font-family: var(--md-font-family-helvetica-neue-bold);
					}
				}
			}
		}

		.market-delayed-info {
			margin-top: 25px;
		}
	}
}

@media screen and (min-width: 0px) and (max-width: 595px) {
	.tab-container {
		.portfolio-wrapper {
			.holding-column {
				flex: 1 0 100%;
				margin: 0;
			}

			.holding-row,
			.transaction-row {
				&:first-child {
					border-top: none;
				}
			}

			.combined-row {
				flex-direction: column;

				.combined-col {
					padding: 12px 0;
					border-top: 1px solid var(--md-color-divider-gray);
					text-align: initial;
					display: flex;
					justify-content: space-between;

					&:first-child {
						border: none;
					}
				}
			}
		}

		.common-row {
			a {
				max-width: 250px !important;
				width: unset;
			}
		}
	}
}

@media screen and (min-width: 596px) and (max-width: 1023px) {
	.last-col {
		max-width: 47%;
	}
}