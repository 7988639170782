.symbolComparisonConatiner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 20px;

  >* {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

.symbolComparisonpopoverContainer {
  background: #fff;
  margin-top: -10px;
  width: 225px;

  .symbolComparisonpopoverContent {
    width: 225px;
    background: #fff;
    box-shadow: -1px 0 6px 2px rgba(0, 0, 0, 0.1),
      1px 4px 6px 2px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    position: absolute;
    z-index: 2;
  }
}