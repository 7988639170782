.message-box {
    padding: 10px;
    margin: 5px 1px;
    border: 1px solid var(--md-color-red);
    background-color: #FFF7F7;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .message-box-content {
        flex: 9.5;
        color: var(--md-color-dark-gray);
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 15px;
        text-align: center;
        font-family: var(--md-font-family-helvetica-neue-medium);
    }

    .close-button {
        flex: .5;
        padding: 0px;
        margin: 0px;
        outline: none;
        border: none;
        background: none;
        cursor: pointer;
        display: flex;
        margin-left: auto;
        box-sizing: border-box;
    }
}

.fail {
    border: 1px solid var(--md-color-red);
}

.success {
    border: 1px solid var(--md-color-green);
    background: #f5fff5;
}

.warning {
    border: 1px solid var(--md-color-yellow);
    background: var(--md-color-light-yellow);
}

.additional-sucsess {
    border: 1px solid var(--md-color-blue);
    background: var(--md-color-light-blue);
}