.option-chain-table-container {
	display: flex;
	flex-direction: column;

	.header-container {
		display: flex;
	}

	&.both-calls-puts.desktop-view {
		.common-row.header {
			&:first-child {
				width: 53%;
			}

			&:not(:first-child) {
				width: 46%;
			}
		}

		.last {
			justify-content: center;
			padding-left: 3%;
		}
	}

	.common-row {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid var(--md-color-divider-gray);
		border-top: 1px solid var(--md-color-divider-gray);
		gap: 5px;
		line-height: 25px;
		width: 100%;

		>span,
		>div {
			padding: 5px;

			@media screen and (max-width: 596px) {
				&:last-child {
					padding-right: 10px;
				}
			}
		}

		>* {
			text-align: right;
			width: 10%;

			&:first-child {
				text-align: left;
				display: flex;
				align-items: center;
			}

			&.open-interest {
				padding-right: 10px;
			}
		}

		.strike-cell-header {
			text-align: center;
		}

		.strike-cell {
			border-right: 1px solid var(--md-color-divider-gray);
			border-left: 1px solid var(--md-color-divider-gray);
			background: var(--md-color-white);
			width: 10%;
			text-align: center;
		}
	}

	.no-data {
		margin: 20px 0;
	}

	.header {
		align-items: end;
		padding-bottom: 10px;

		>span {
			color: var(--md-color-dark-gray);
			font-family: var(--md-font-family-helvetica-neue-regular);
			font-size: var(--md-font-size-small);
			letter-spacing: 0;
			line-height: 18px;
		}
	}

	.itm,
	.otm {
		display: flex;

		.calls-table-wrapper {
			width: 54%;
		}

		.puts-table-wrapper {
			width: 46%;
		}
	}

	.last {
		text-align: left;
		padding: 10px;
		padding-left: 15%;
		display: flex;
		border-bottom: 1px solid var(--md-color-divider-gray);
	}

	.scrollable-content {
		overflow-y: auto;
		height: 36vh;
	}
}

@media screen and (min-height: 701px) {
	.option-chain-table-container {
		.scrollable-content {
			height: 58vh;
		}
	}
}

@media screen and (max-width: 596px) {
	.option-chain-table-container {
		// padding: 0 10px;

		.common-row {
			align-items: center;
			padding-bottom: 0;

			>* {
				width: auto;
			}
		}

		.last {
			justify-content: center;
			padding-left: 3%;
		}
	}
}