.options-container {
	// border: 1px solid var(--md-color-blur-white);
	//    padding: 10px;
	background-color: var(--md-color-white);

	@media screen and (max-width: 596px) {
		padding: 0;
	}

	&.overlay-on {
		pointer-events: none;
		position: relative;

		.dropDownMain .basicDropDownContainer {
			position: initial;
		}
	}

	.quote-bar-container {
		display: flex;
		flex-direction: column;
		gap: 15px;
		margin-bottom: 15px;

		@media screen and (max-width: 596px) {
			//    padding: 0 10px;
		}
	}

	.mobile-overlay {
		background-color: rgba(0, 0, 0, 0.4);
		pointer-events: none;
		transition: opacity 0.3s ease-in-out;
		width: 100%;
		position: absolute;
		height: 100vh;
	}

	.mobile-change-view-drawer {
		.wrapper {
			padding: 15px;

			.max-limit-note {
				margin: 10px 0;
			}

			.checkbox-wrapper input[type='checkbox']+label {
				border: 2px solid var(--md-color-blue);
			}

			.row {
				display: flex;
				justify-content: space-between;
				padding: 10px 0;

				&.header {
					border-bottom: 1px solid var(--md-color-blur-white);
				}

				&.footer {
					gap: 10px;
					border-top: 1px solid var(--md-color-blur-white);

					button {
						width: 50%;
					}
				}
			}
		}

		&.close {
			display: none;
		}

		&.open {
			display: block;
			position: sticky;
			bottom: 0;
			width: 100%;
			background: var(--md-color-white);
			pointer-events: all;
			z-index: 9999;
		}
	}
}