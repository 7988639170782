.home-page-container {
    font-family: var(--md-font-family-helvetica-neue-regular);

    h1 {
        font-size: 25px;
        color: var(--md-color-dark-blue);
        box-sizing: content-box;
        margin: 30px 0 30px 0;
        text-align: center;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    input[type='text'] {
        width: 300px;
        height: 40px;
        padding: 5px;
        border: 1px solid #063f61;
        border-radius: 3px;
        font-size: 16px;
        box-sizing: border-box;
    }

    input[type='button'],
    a {
        width: 300px;
        padding: 10px;
        background-color: #063f61;
        border: none;
        color: #fff;
        border-radius: 3px;
        cursor: pointer;
        font-family: inherit;
        text-decoration: none;
        display: block;
        text-align: center;
        font-size: 15px;
    }

    .separator {
        margin: 30px 0;
    }

    .valid-token {
        color: green;
        font-weight: bold;
    }

    .invalid-token {
        color: red;
        font-weight: bold;
    }
}