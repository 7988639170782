#portfolio-tracker-tabs-container {
	background: var(--md-color-white);
	width: 100%;
	box-sizing: border-box;

	.loading-wrapper {
		margin-bottom: 20px;
	}

	.no-data-container {
		margin: 70px 0;

		.label {
			font-size: var(--md-font-size-small);
			color: var(--md-color-dark-gray);
		}
	}

	.name {
		color: var(--md-color-black);
		font-family: var(--md-font-family-palatino-regular);
		font-size: 36px;
		margin: 20px 0;
		letter-spacing: 0;
		line-height: 32px;
	}

	.tab-headers {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid var(--md-color-blur-white);
		border-top: 2px solid var(--md-color-blur-white);
		margin: 0 10px;

		&>div {
			padding-left: 0;
		}

		#portfolio-tracker-tabs {
			margin: 0;
			justify-content: space-between;

			li {
				&[class*='tab_list_active'] {
					div {
						span {
							color: var(--md-color-blue);
							font-weight: bold;
						}
					}
				}

				div {
					height: 30px;

					span {
						color: var(--md-color-light-grey);
						// TODO: change to palatino-bold once font is available
						font-family: var(--md-font-family-palatino-regular);
						font-size: var(--md-font-size-regular);
						letter-spacing: 0;
						line-height: 19px;
					}
				}
			}
		}
	}

	.export-btn-container {
		a {
			color: var(--md-color-blue);
			display: flex;
			gap: 5px;
			font-family: var(--md-font-family-helvetica-neue-bold);
			font-size: var(--md-font-size-small);
			line-height: 18px;
			text-decoration: underline;
			cursor: pointer;

			svg {
				padding-top: 3px;
			}
		}
	}

	.upper-view {
		.pie-chart-master {
			display: flex;
			gap: 20px;

			.pie-chart-portfolio {
				width: calc(50% - 10px);
				height: 250px;
			}

			.pie-chart-related-details {
				width: calc(50% - 10px);
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				margin-top: 21px;

				.asset-type {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					gap: 20px;
					margin-top: 21px;

					.asset-container {
						display: flex;
						gap: 10px;
						align-items: center;
						text-transform: uppercase;

						.asset-color-square {
							width: 14px;
							height: 14px;
						}
					}
				}
			}
		}
	}

	.tab-container {
		padding: 0 10px;
	}

	.hide-label {
		display: none;
	}
}

@media screen and (min-width: 0px) and (max-width: 1024px) {
	#portfolio-tracker-tabs-container {
		.tab-headers {
			#portfolio-tracker-tabs {
				li {
					&[class*='tab_list_active'] {
						div {
							span {
								font-weight: initial;
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (min-width: 0px) and (max-width: 595px) {
	#portfolio-tracker-tabs-container {
		.name {
			font-size: var(--md-font-size-extra-large);
			line-height: 32px;
		}

		.chart-container {
			min-height: initial;
			padding-left: 0;
		}

		.tab-headers {
			align-items: initial;
			border-bottom: 1px solid var(--md-color-blur-white);
			flex-direction: column;

			>div:first-child {
				border-bottom: 1px solid var(--md-color-blur-white);
			}

			.export-btn-container {
				margin: 12px 0;
				margin-left: auto;
			}
		}

		.upper-view {
			margin: 15px 0px;

			.pie-chart-master {
				flex-direction: column;
				align-items: flex-start;
				padding: 0 10px;
				gap: 0px;

				.pie-chart-portfolio {
					height: initial;
					display: flex;
					flex-direction: column;
					width: 100%;

					.heading {
						text-align: left;
						font-family: var(--md-font-family-palatino-regular);
						font-size: 14px;
						letter-spacing: 0;
						color: var(--md-color-black);
						line-height: 19px;
						width: 50%;
					}

					.no-data-pie-chart {
						padding: 30px 0;
					}

					.pie-chart-related-details {
						margin-top: 0;
					}
				}
			}

			.hide-label {
				display: block;

				>a {
					color: var(--md-color-blue) !important;
					text-decoration: underline;
					font-family: var(--md-font-family-helvetica-neue-bold);
					font-size: var(--md-font-size-small);
					line-height: 15px;
					white-space: nowrap;
					display: block;
				}
			}
		}
	}
}