.most-recent-container {
    .component-header {
        margin-bottom: 10px;
    }

    .no-data {
		min-height: 120px;
        height: auto;
        margin: 20px 0;
    }
}