.multi-checkbox-wrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 15px 0px;

    .checkbox-wrapper {
        display: flex;
        justify-content: space-between;
        font-family: var(--md-font-family-helvetica-neue-medium);
        font-size: var(--md-font-size-small);
    }
}