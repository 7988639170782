.label {
	display: inline-block;
	font-size: var(--md-font-size-small);
	color: var(--md-color-black);
	font-family: var(--md-font-family-helvetica-neue-regular);
	letter-spacing: 0;
}

.boldLabel {
	font-family: var(--md-font-family-helvetica-neue-medium);
}

.neue-medium {
	font-family: var(--md-font-family-helvetica-neue-medium);
}


.greyLabel {
	color: var(--md-color-light-grey);
}

.ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
}

.label-xs {
	font-size: 8px;
	line-height: 17px;
}

.label-s {
	font-size: 11px;
	line-height: 18px;
}

.label-ms {
	font-size: var(--md-font-size-regular);
	line-height: 20px;
}

.label-m {
	line-height: 18px;
}

.label-l {
	font-size: var(--md-font-size-large);
}

.align-center {
	text-align: center;
}

.align-left {
	text-align: left !important;
}

.align-right {
	text-align: right;
}

.neue-regular {
	font-family: var(--md-font-family-helvetica-neue-regular);
}

.neue-bold {
	font-family: var(--md-font-family-helvetica-neue-bold);
}

.palatino-regular {
	font-family: var(--md-font-family-palatino-regular);
}

.display-block {
	display: block;
}

.darkGreyLabel {
	color: var(--md-color-dark-gray);
}