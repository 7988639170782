.overview-container {
	.first-row {
		display: flex;
		justify-content: space-between;

		.left-side {
			min-width: 399px;

			.no-chart-container {
				border: 1px solid var(--md-color-blur-white);
			}

			.charts-price {
				.chart img {
					width: 100%;
				}
			}

			.chart-details {
				display: flex;
				justify-content: space-between;
				margin-top: 19px;
				margin-bottom: 17px;
				align-items: center;
				white-space: nowrap;

				.chart-date-show {
					font-size: 11px;
					line-height: var(--md-font-size-small);
					font-family: var(--md-font-family-helvetica-neue-regular);
					color: var(--md-color-light-grey);
				}

				.chart-date-hide {
					display: none;
				}

				.chart-period {
					display: flex;
					justify-content: space-between;
					align-items: center;
					min-width: 27%;
					font-size: var(--md-font-size-small);
					line-height: var(--md-font-size-regular);
					font-family: var(--md-font-family-helvetica-neue-regular);
					color: var(--md-color-light-grey);

					> a {
						line-height: var(--md-font-size-regular);
						cursor: pointer;
					}

					.labelLink-active {
						text-decoration: underline;
					}
				}
			}

			.justified-details {
				justify-content: end;
			}

			.chart-aligned-details {
				margin-top: 39px;
			}
		}

		.right-side {
			width: 100%;
			padding-left: 21px;
			display: flex;
			flex-direction: column;

			> h3 {
				text-align: initial;
			}
		}
	}

	.separate {
		margin-bottom: 13px;
	}
}

@media screen and (min-width: 596px) {
	.right-side {
		> span {
			padding-bottom: 10px;
		}
	}
	.overview-profile {
		.loading-wrapper {
			min-height: 200px;
		}
	}
}
@media screen and (min-width: 596px) and (max-width: 1350px) {
	.overview-container {
		.first-row {
			.left-side {
				min-width: 52%;

				.chart-details {
					.chart-period {
						min-width: 32%;
					}
				}
			}
		}
	}
}

@media screen and (min-width: 0px) and (max-width: 595px) {
	.overview-container {
		.first-row {
			display: block;

			.left-side {
				min-width: 100%;

				.chart-details {
					.chart-period {
						min-width: 35%;
					}
				}

				.chart-aligned-details {
					margin-top: 19px;
				}
			}

			.right-side {
				margin-bottom: 15px;
				padding-left: unset;

				.loading-wrapper {
					margin: 20px 0;
				}
			}
		}

		.separate {
			margin-left: 5px;
			margin-bottom: unset;
		}

		.overview-profile {
			margin-left: 5px;
		}
	}
}
