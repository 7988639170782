.how-to-integrate-page-container {
    font-family: var(--md-font-family-helvetica-neue-regular);

    h1 {
        font-size: 25px;
        color: var(--md-color-dark-blue);
        box-sizing: content-box;
        margin: 30px 0 30px 0;
        text-align: center;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;


    .wc-code {
        width: 90%;

        summary {
            background-color: #063f61;
            color: white;
            padding: 5px 5px;
            margin-top: 10px;
        }

        .sample-code-con {
            padding: 10px;
            background-color: black;

            &.white {
                background-color: white;
                border: 1px solid black;

                h3 {
                    color: #063f61;
                }

                pre {
                    background-color: black;
                    color: white;
                    padding: 10px 5px;
                }
            }

            summary {
                background-color: white;
                color: black;
                padding: 5px 5px;
                margin-top: 10px;
            }

            .code {
                border: 1px solid white;
                color: white;
                padding: 6px;
            }

            h3 {
                color: white;
                text-align: center;
                font-size: 18px;
            }
        }
    }
}