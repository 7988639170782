.snapshot-main-container {

    div[data-attribute='true'] {

        >div>ul {
            display: none;
        }

        div[id=snapshot-header] {
            display: none;
        }
    }

    .lower-container {
        margin: 32px 0px;

        .peer-container {
            display: block;
            width: 100%;
            height: unset;

            #peers-container {
                .symbol-details-container {
                    .symbol-details-label {
                        >a {
                            max-width: 110px;
                        }
                    }
                }
            }
        }

        .third-row {
            display: flex;
            justify-content: space-between;

            .left-side {
                width: 50%;
                height: auto;
            }

            .right-side {
                width: 50%;
                padding-left: 25px;
            }

            .container-margin {
                margin-bottom: 8px;
            }
        }
    }

    #snapshot-tabs {
        border-bottom: 1px solid var(--md-color-blur-white);
    }
}

@media screen and (min-width: 0px) and (max-width: 1024px) {
    .snapshot-main-container {
        .lower-container {
            margin-top: 25px;

            .peer-container {
                width: 100%;
                height: unset;
            }

            .third-row {
                display: block;

                .left-side {
                    width: 100%;
                    height: unset;
                }

                .right-side {
                    width: 100%;
                    padding-left: unset;
                    margin-top: 25px;
                }
            }
        }
    }
}

#equity-snapshot-tabs {
    border-bottom: 1px solid var(--md-color-blur-white);

    >li {
        font-size: var(--md-font-size-regular);
        letter-spacing: 0;

        >div {
            height: 27px;
        }
    }

    >li[aria-selected="true"] {
        color: var(--md-color-blue);
        font-family: var(--md-font-family-palatino-bold);
        line-height: 15px;
        border-bottom: 2px solid var(--md-color-dark-blue-solid);
    }

    >li[aria-selected="false"] {
        color: var(--md-color-light-grey);
        font-family: var(--md-font-family-palatino-regular);
        line-height: 19px;
    }
}

@media screen and (min-width: 0px) and (max-width: 595px) {
    #equity-snapshot-tabs {
        justify-content: space-between;

        :first-child>div>span {
            margin-left: -8px;
        }

        :last-child>div>span {
            margin-right: -12px;
        }

    }

    #news-by-category-container {
        padding: unset;
    }
}