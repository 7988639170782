.save-settings-container {
    .loading-wrapper {
        padding: 20px 0;
    }

    .settings-name {
        font-family: var(--md-font-family-helvetica-neue-medium);
        font-size: 12px;
        line-height: 20px;
        color: var(--md-color-black);
        display: flex;
        justify-content: space-between;

        .textbox-container {
            width: 70%;
            height: 36px;
        }

        button {    
            width: 25%;
        }
    }

    .table-container {
        table {
            font-family: var(--md-font-family-helvetica-neue-regular);
            font-size: 12px;
            line-height: 18px;

            th {
                border-top: none !important;
                padding: 4px 2px 5px 2px;

                span {
                    color: var(--md-color-dark-gray);
                    border-top: none;
                    font-family: inherit;
                    font-size: 12px;
                    line-height: 18px;
                }

                td {
                    padding: 2px 2px 5px 2px;
                }
            }

            tr {
                border-top: none;

                td:nth-child(2) {
                    text-align: right;
                }

                .show-horizontal-border {
                    border-top: none;
                }

                td {
                    padding: 2px 2px 5px 2px;

                    .delete-button {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}