.symbol-search-container {
    a {
        cursor: pointer;
        color: var(--md-color-blue);
        text-decoration: underline;
    }

    .search-section {
        .search-row {
            display: flex;
            margin: 10px 0;
            gap: 10px;
        }

        .first-row {
            div {
                flex-basis: 50%;
            }

            .textbox-container {
                width: 100%;
            }
        }

        .second-row {
            .security-type-dropdown {
                flex-basis: 77.5%;
            }
        }
    }

    .search-results-and-symbology {
        max-height: 67vh;
        overflow-y: auto;
        height: auto;
    }
}