.number {
	display: inline-block;
}

.negative {
	.label {
		color: var(--md-color-red) !important;
	}
}

.positive {
	.label {
		color: var(--md-color-green) !important;
	}
}
