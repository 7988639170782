.news-chart-container {
    .loader {
        height: 240px;
    }

    .no-data {
        height: 240px;
        display: flex;
    }

    .custom-tooltip {
        padding: 5px 9px;

        .first-line {
            font-family: var(--md-font-family-palatino-regular);
            font-size: var(--md-font-size-regular);
            line-height: 19px;
            color: var(--md-color-black);
            letter-spacing: 0.29px;
        }

        .second-line {
            font-family: var(--md-font-family-helvetica-neue-regular);
            font-size: var(--md-font-size-regular);
            line-height: 12px;
            color: var(--md-color-dark-gray);
            letter-spacing: 0.23px;
        }
    }

    .highcharts-graph.zone-1 {
        stroke: var(--md-color-dull-blue);
    }
}

.slideshow-container {
    max-width: 1000px;
    position: relative;
    margin: auto;

    .carousel {
        width: 100%;
        text-align: center;
        margin-bottom: 14px;

        .prev,
        .next {
            color: var(--md-color-chevron-blue);
            transition: 0.6s ease;
            border-radius: 0 3px 3px 0;
            user-select: none;
            padding: 0px 15px;
        }

        .disable {
            color: var(--md-color-disable-grey);
        }

        .next {
            right: 0;
            border-radius: 3px 0 0 3px;
        }

        .chart-title {
            color: var(--md-color-blue);
            font-family: var(--md-font-family-helvetica-neue-bold);
            font-size: var(--md-font-size-small);
            letter-spacing: 0;
            line-height: 15px;
        }
    }

    @-webkit-keyframes fade {
        from {
            opacity: .4
        }

        to {
            opacity: 1
        }
    }

    @keyframes fade {
        from {
            opacity: .4
        }

        to {
            opacity: 1
        }
    }
}