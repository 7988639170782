.dropDownMain {
    display: inline-flex;
    align-items: center;
    width: 100%;

    .basicDropDownContainer {
        display: inline-block;
        border: 1px solid var(--md-color-blur-white);
        background-color: var(--md-color-white);
        position: relative;
        padding: 0;
        margin: 0;
        list-style: none;
        border-radius: 1px;
        outline: none;
        width: 100%;
        height: 39px;

        .selected-value {
            display: flex;
            justify-content: space-between;
            box-sizing: border-box;
            height: 38px;
            align-items: center;
        }

        li:first-child {
            padding: 7px 18px 7px 12px;

        }

        &.open {
            border-radius: 0px;
            border: 1px solid var(--md-color-blue);
        }

        li {
            border: none;
            display: block;
            cursor: pointer;
            border-top: 1px solid var(--md-color-off-white);
            background: var(--md-color-white);
            text-align: left;

            .name {
                overflow: hidden;
                display: inline-block;
                text-overflow: ellipsis;
                white-space: nowrap;
                box-sizing: border-box;
                margin-right: 5px;
                width: 70%;
                height: 20px;
                color: var(--md-color-dark-gray);
                font-family: var(--md-font-family-helvetica-neue-regular);
                font-size: var(--md-font-size-small);
                letter-spacing: 0;
                line-height: 20px;
                position: relative;
            }

            &.hide {
                display: none;
            }

            .li-hover,
            li:hover {
                background: var(--md-color-chart-border);

                span.name {
                    color: var(--md-color-white);
                }

                svg {
                    color: var(--md-color-white);
                }
            }

            .icon {
                // To add
            }
        }

        .basicDropDownValueContainer {

            ul {
                width: 100%;
                background: var(--md-color-white);
                position: absolute;
                z-index: 2;
                padding: 0px;
                margin: 0px;
                margin-top: 3px;
                box-shadow: 0 1px 5px 0 rgba(43, 109, 159, 0.15);
                max-height: 225px;
                overflow-y: auto;
            }

            li {
                padding: 12px 18px 12px 6px;
                display: flex;
                align-items: center;
                gap: 7px;

                .check-icon {
                    width: 14px;
                }
            }

            li:not(:first-child) {
                border-top: 1px solid var(--md-color-divider-gray);
            }
        }

        .hide {
            display: none;
        }
    }
}

@media screen and (min-width: 0px) and (max-width: 595px) {
    [data-type="multi-select"] {
        &.dropDownMain {
            .basicDropDownContainer {
                li {
                    .name {
                        height: 25px;
                    }
                }
            }
        }
    }
}