.no-data {
	display: flex;
	width: 100%;
	justify-content: center;
	flex-direction: column;
	gap: 10px;
	align-items: center;
	height: 100%;
}

.height {
	min-height: 100px;
	padding-bottom: 32px;
}
