.textarea-container {
  label {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .textarea {
    font-size: var(--md-font-size-small);
    font-family: var(--md-font-family-helvetica-neue-regular);
    padding: 7px 18px 7px 12px;
    border: 1px solid var(--md-color-blur-white);
    box-sizing: border-box;
    width: 100%;

    &:focus {
      outline: none;
      border-radius: 0px;
      border: 1px solid var(--md-color-blue);
    }
  }

  .textarea::placeholder {
    color: var(--md-color-dark-gray);
  }
}