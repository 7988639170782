.heading {
    color: var(--md-color-black);
    font-family: var(--md-font-family-palatino-regular);
    letter-spacing: 0;
    margin: 0;
    padding: 0;
    font-weight: 400;
}

.heading-h1 {
    height: 32px;
    font-size: var(--md-font-size-extra-jumbo-large);
    line-height: 36px;
}

.heading-h2 {
    height: 32px;
    font-size: var(--md-font-size-extra-large);
    line-height: 32px;
}

.heading-h3 {
    font-size: 20px;
    text-align: center;
    font-family: var(--md-font-family-palatino-regular);
    line-height: 26px;
}

.heading-h4 {
    height: 20px;
    font-size: var(--md-font-size-regular);
    line-height: 20px;
}

.heading-h5 {
    font-family: var(--md-font-family-helvetica-neue-regular);
    font-size: var(--md-font-size-large);
    height: 17px;
    text-align: center;
}

.heading-h6 {
    line-height: 19px;
    font-size: var(--md-font-size-regular);
}

@media screen and (min-width: 0px) and (max-width: 1023px) {
    .heading-h2 {
        font-size: var(--md-font-size-large);
    }

    .heading-h5 {
        font-family: var(--md-font-family-helvetica-neue-regular);
        font-size: var(--md-font-size-large);
        letter-spacing: 0;
        line-height: 17px;
        text-align: center;
    }
}