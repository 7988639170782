.profile-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;

    .profile-info {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-bottom: 10px;
        padding-right: 10px;

        &.one-column {
            .profile-column {
                width: 100%;
            }
        }

        &.multi-column {
            .profile-column {
                width: 47%;
            }
        }

        .profile-column {
            .profile-field {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 5px;
                border-bottom: 1px solid var(--md-color-blur-white);
                width: 100%;
            }
        }

        .profile-column_1 {
            .last-row {
                border-bottom: unset;
                padding-top: 15px;
            }
        }
    }

    .delay-info-wrapper {
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
    }
}

@media screen and (min-width: 0px) and (max-width: 595px) {
    .profile-collapse-container {
        .collapsible-container {
            details {
                border: unset;
                padding: 15px 0px 0px 0px !important;

                .content-container {
                    .profile-container {
                        .profile-info {
                            .profile-column_0 {
                                .last-row {
                                    border-bottom: unset;
                                    justify-content: flex-start;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}