.indices-chart-container {
    width: 100%;

    .header {
        padding-bottom: 20px;
    }

    .no-data-container {
        display: flex;
        align-items: center;
        height: 100px;
        margin-top: 60px;
    }

    .charts-price {
        max-width: 92%;
        max-height: 240px;
        display: flex;
        justify-content: center;
        width: calc(100% - 1.5px);
        padding: 6px 4px 10px 6px;
        box-sizing: border-box;
    }

    .indices-details-keys {
        text-align: center;
        display: flex;
        justify-content: space-between;
        margin: 20px 15px 0px 2px;

        span {
            text-align: center;
            font-family: var(--md-font-family-helvetica-neue-regular);
            font-size: 12px;
            line-height: 27px;
            color: var(--md-color-dark-gray);
            width: 33%;

            &:nth-child(1) {
                vertical-align: bottom;
            }
        }
    }

    .indices-details-values {
        display: flex;
        justify-content: space-between;
        margin: 10px 15px 0px 2px;
        text-align: center;
        color: var(--md-color-black);

        div.col-last-value {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        >div {
            width: 33%;
            border-right: 2px solid var(--md-color-blur-white);

            &:last-child {
                border: none;
            }
        }

        span {
            text-align: center;
            font-family: var(--md-font-family-helvetica-neue-bold);
            font-size: 16px;
            line-height: 9px;
            vertical-align: middle;
        }

        .divider {
            font-family: var(--md-font-family-helvetica-neue-regular);
            font-size: 45px;
        }

        .indices-details-volume {
            span {
                text-align: center;
                font-family: var(--md-font-family-helvetica-neue-bold);
                font-size: 14px;
                line-height: 17px;
                padding: 0 10px;

            }
        }
    }
}

@media screen and (min-width: 0px) and (max-width: 595px) {
    .header {
        padding-bottom: 20px;

        .heading {
            height: auto;
        }
    }

    .no-data-container {
        display: flex;
        align-items: center;
        height: 100px;
        margin-top: 60px;
    }

    .charts-price {
        max-width: 100%;
        max-height: 240px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100% !important;
        border: none !important;
        padding: 6px 4px 10px 6px;
        box-sizing: border-box;
    }

    .indices-details-keys {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 20px 0px 0px 2px;
        padding: 5px;
        align-content: center;

        span {
            text-align: center;
            font-family: var(--md-font-family-helvetica-neue-regular);
            font-size: 11px;
            line-height: 27px;
            letter-spacing: 0;
            color: var(--md-color-dark-gray);
        }

        span:last-child {
            padding-left: 10px;
        }
    }

    .indices-details-values {
        display: flex;
        justify-content: space-between;
        width: 100% !important;
        margin: 10px 0px 0px 2px !important;
        text-align: center;
        color: var(--md-color-black);

        span {
            text-align: center;
            font-family: var(--md-font-family-helvetica-neue-bold);
            font-size: 12px !important;
            line-height: 9.62px;
            letter-spacing: 0;
        }

        .divider {
            font-family: var(--md-font-family-helvetica-neue-regular);
            font-size: 45px;
        }

        .indices-details-volume {
            span {
                text-align: center;
                font-family: var(--md-font-family-helvetica-neue-bold);
                font-size: 14px;
                line-height: 17px;

            }
        }
    }
}