.searchbox-container {
  display: flex;
  max-height: 40px;

  .searchbox-text {
    display: flex;
  }

  label {
    font-weight: bold;
    margin-bottom: 10px;
  }

  .textbox {
    font-family: var(--md-font-family-helvetica-neue-regular);
    font-size: var(--md-font-size-small);
    padding: 7px 18px 7px 12px;
    border: 1px solid var(--md-color-blur-white);
    min-width: 150px;

    &:focus {
      outline: none;
      border-radius: 0px;
      border: 1px solid var(--md-color-blue);
    }
  }

  .search-button {
    font-family: var(--md-font-family-palatino-regular);
    font-size: 14px;
    line-height: 18px;
    height: 40px;
    width: 90px;
    background: transparent !important;
    color: var(--md-color-black) !important;

    svg {
      margin-left: -50%;
    }

    span {
      position: absolute;
      margin-top: 2px;
      padding-left: 10px;
    }

    &:hover {
      background: transparent !important;
      color: var(--md-color-black) !important;
    }

  }

  .go-button {
    display: flex;
		justify-content: center;
		align-items: center;
		font-size: 14px;
		background: var(--md-color-blue);
		z-index: 2;
		height: 40px;
		width: 50px;
		cursor: pointer;
  }
}