.overlay-popup {

    .overlay-item {
        display: block;
        width: 95%;
        padding: 8px 0px 2px 15px;
        border-bottom: 1px solid var(--md-color-divider-gray);
        overflow: hidden;

        a {
            font-family: var(--md-font-family-helvetica-neue-regular);
            font-size: 12px;
            line-height: 20px;
            color: var(--md-color-dark-gray);

        }
    }
}