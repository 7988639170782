.radio-container {
    display: flex;
    align-items: center;

    .radio-input {
        display: none;
    }

    .radio-label {
        margin-left: 8px;
        cursor: pointer;
        position: relative;

        &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }

        &::before {
            content: '';
            display: inline-block;
            width: 20px;
            height: 20px;
            border: 2px solid var(--md-color-blue);
            border-radius: 50%;
            margin-right: 6px;
            vertical-align: middle;
            background-color: transparent;
            box-sizing: content-box;
        }

        &::after {
            content: '';
            position: absolute;
            top: 4px;
            left: 4px;
            width: 16px;
            height: 16px;
            background-color: var(--md-color-blue);
            border-radius: 50%;
        }
    }

    .radio-input:not(:checked)+.radio-label::after {
        background-color: var(--md-color-white)
    }
}