.back-button {
	color: var(--md-color-blue);
	display: flex;
	gap: 5px;
	font-family: var(--md-font-family-helvetica-neue-bold);
	font-size: var(--md-font-size-small);
	line-height: 18px;
	font-weight: bold;
	margin-bottom: 16px;

	.back-link {
		color: inherit;
		text-decoration: underline;
		text-decoration-skip-ink: none;
		cursor: pointer;
	}
}

.create-portfolio-container {
	background: var(--md-color-white);
	padding: 11px 22px 20px 22px;
	width: 100%;
	box-sizing: border-box;

	.loader-container {
		margin: 20px 0;
	}

	.header {
		.create-p-upper-container {
			margin-top: 9px;

			.portfolio-name-container {
				display: flex;
				align-items: center;
				box-sizing: border-box;
				gap: 10px;
				padding: 10px 0px;
				border-top: 1px solid var(--md-color-divider-gray);
				border-bottom: 1px solid var(--md-color-divider-gray);

				input[data-key='name'] {
					min-width: 200px;
				}

				input[type='number'] {

					/* Chrome, Safari, Edge, Opera */
					input::-webkit-outer-spin-button,
					input::-webkit-inner-spin-button {
						-webkit-appearance: none;
						margin: 0;
					}

					/* Firefox */
					input[type='number'] {
						-moz-appearance: textfield;
					}
				}
			}

			.portfolio-notes-container {
				padding: 17px 0px;
				border-bottom: 2px solid var(--md-color-blur-white);

				textarea {
					height: 90px;
					resize: none;
				}

				.portfolio-notes-header {
					padding-bottom: 16px;
					display: flex;
					align-items: center;
					gap: 5px;
				}
			}
		}
	}

	.create-p-footer {
		position: relative;
		bottom: 0px;
		display: flex;
		gap: 10px;
		align-items: center;
		justify-content: center;

		.common-button {
			min-width: 200px;
			margin-top: 20px;

			button {
				width: 100%;
			}
		}
	}
}

@media screen and (min-width: 596px) and (max-width: 1023px) {
	.intraday-charts-container {
		padding: 11px 8px 18px 19px;

		.charts-container {
			gap: 20px;
		}
	}
}

@media screen and (min-width: 0px) and (max-width: 595px) {
	.create-portfolio-container {
		padding: 0px;
		margin-top: 20px;

		.header {
			h2.heading-h2 {
				font-size: var(--md-font-size-large);
			}

			.create-p-upper-container {
				padding: 8px 0px;

				.portfolio-name-container {
					input[data-key='name'] {
						min-width: 175px;
						padding-right: 5px;
					}

					>*:nth-child(3) {
						display: none;
					}
				}

				.portfolio-notes-container {
					padding: 16px 0px 14px 0px;

					textarea {
						height: 70px;
					}
				}
			}
		}

		.create-p-footer {
			.common-button {
				min-width: unset;
				margin-top: 17px;
				width: 40%;
			}
		}
	}
}