.labelLink {
    color: var(--md-color-black);
    font-family: var(--md-font-family-palatino-regular);
    letter-spacing: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inherit;
    cursor: pointer;

    .labelLink-icon {
        padding: 0px;
    }

    &.company-name {
        font-size: 12px;
        font-family: var(--md-font-family-helvetica-neue-bold);
        color: var(--md-color-blue);
    }
}

.labelLink:hover {
    text-decoration: underline;
    text-decoration-skip-ink: none;
    cursor: pointer;
}

.labelLink-active {
    color: var(--md-color-blue) !important;
    font-weight: bold;
}

.bold-link {
    font-family: var(--md-font-family-helvetica-neue-medium);
}

.greyLabel {
    color: var(--md-color-light-grey);
}

.labelLink-s {
    font-size: var(--md-font-size-small);
    line-height: 18px;
}

.labelLink-m {
    font-size: var(--md-font-size-regular);
    line-height: 19px;
}

.labelLink-l {
    height: 20px;
    line-height: 26px;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 125px;
}