#peers-container {
    display: flex;
    flex-direction: column;
    font-family: inherit;
    justify-content: space-between;

    table th:not(:first-child),
    td:not(:first-child) {
        text-align: right;
    }

    .symbol-details-container {
        .symbol-details-label {
            >a {
                max-width: 80px;
            }
        }
    }

    table {
        margin-top: 0;

        td,
        th {
            padding: 10px 0;
        }

        tr:first-child {

            th,
            td {
                border-top: none
            }
        }
    }

    .no-peer-label {
        border-bottom: 1px solid var(--md-color-divider-gray);
        padding: 0px 0px 10px 0px;
        margin-bottom: 10px;
    }

    @media screen and (min-width: 375px) and (max-width: 595px) {
        .symbol-details-container {
            .symbol-details-label {
                width: 85px
            }
        }
    }

    @media screen and (min-width: 596px) and (max-width: 1024px) {
        .symbol-details-container {
            .symbol-details-label {
                width: 150px
            }
        }
    }
}