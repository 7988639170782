 .confirm-dialog-container {
     color: var(--md-color-black);
     font-family: var(--md-font-family-palatino-regular);
     font-weight: 500;
     letter-spacing: 0;
     line-height: 18px;

     .modal-overlay .modal-container .modal-body {
         font-size: 14px;

     }

     .button {
         margin: 10px;

         &+& {
             margin-left: 8px;
         }
     }
 }