.toggle-link-container {
    padding: 15px;
    border-left: 1px solid var(--md-color-black);
    border-bottom: 1px solid var(--md-color-black);
    color: var(--md-color-black);
    font-family: var(--md-font-family-helvetica-neue-regular);
    font-size: var(--md-font-size-small);
    letter-spacing: 0;

    &:last-of-type {
        border-bottom: none;
    }

    .toggle-link-header {
        display: inline-block;
        cursor: pointer;
        font-size: var(--md-font-size-regular);
        font-family: var(--md-font-family-palatino-regular);

        svg {
            padding-right: 15px;
        }
    }

    .toggle-link-content {
        margin-top: 10px;
    }
}