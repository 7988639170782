@media screen and (min-width: 596px) and (max-width: 1024px) {
	.news-map-container {
		.container-row {
			display: flex;
		}
	}
}

@media screen and (min-width: 0px) and (max-width: 595px) {
	.news-map-container {
		border: none;
	}
}