.symbology-view {
    a {
        cursor: pointer;
        color: var(--md-color-blue);
        text-decoration: underline;
        font-family: var(--md-font-family-helvetica-neue-regular);
        font-size: var(--md-font-size-small);
        font-weight: bold;
    }

    .symbology-toggle {
        padding: 15px 0;
        display: inline-block;
    }

    .symbology-content {
        font-size: var(--md-font-size-small);
        letter-spacing: 0;

        .symbology-content-header {
            color: var(--md-color-black);
            font-family: var(--md-font-family-palatino-regular);
            font-size: var(--md-font-size-regular);
            letter-spacing: 0;
            line-height: 18px;
            margin-bottom: 10px;
        }

        .static-content {
            margin-bottom: 20px;
            color: var(--md-color-dark-gray);
            font-family: var(--md-font-family-helvetica-neue-regular);
            font-size: var(--md-font-size-small);
            letter-spacing: 0;
            line-height: 18px;
        }
    }
}