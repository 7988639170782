.option-popover-container {
    padding: 10px 0;

    .symbol {
        display: flex;
        margin: 10px 10px 0 10px;
        color: var(--md-color-blue);
        font-weight: 500;
        letter-spacing: 0.29px;
        line-height: 17px;
        padding-bottom: 10px;
        border-bottom: 1px solid var(--md-color-blur-white);
    }

    .profile-container {
        padding: 0 10px;

        .profile-info {
            padding-right: 0;

            .profile-column {
                width: 100%;

                .profile-field {
                    padding: 5px 0;

                    .label:nth-of-type(2),
                    span:nth-of-type(2) .label {
                        font-family: var(--md-font-family-helvetica-neue-bold);
                    }
                }
            }
        }
    }
}