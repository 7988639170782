.news-tab-container {
    .quote-bar-container {
        display: flex;
        flex-direction: column;
        padding-bottom: 14px;
        margin-bottom: 15px;
        border-bottom: 1px solid var(--md-color-gray);

        .delayed-info {
            margin-top: 13px;
        }
    }
}